    import {IconWidget, Loader, PivotAnalysis} from 'components';
    import moment from 'moment';
    import React, {useState} from 'react';
    import {withRouter} from 'react-router';
    import {Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row, Table} from 'reactstrap';
    import {connect} from 'react-redux';
    import {Page, Strategy,} from '../components';
    import {useQuery} from "react-query";
    import {getStrategyTracker, getTechnicalAnalysisBySymbol} from "../api/realtime";
    import {SummaryTable} from "../components/WatchListTable";
    import {MdAlarmOn, MdLightbulbOutline, MdSyncDisabled, MdThumbDown, MdThumbUp} from "react-icons/md";

    function mapStateToProps(state) {
        return {
            token: state.auth.authenticated,
        };
    }

    // export default connect(mapStateToProps)(withRouter(TechnicalAnalysisPage));

    const StrategyTrackerPage = props => {
        const searchParams = new URLSearchParams(props.location.search);
        const symbol = searchParams.get('symbol');
        const strategyName = searchParams.get('strategyName');
        const {data, isError, error, isLoading, isFetched} = useQuery(
            ['Tracker', symbol, strategyName],
            () => getStrategyTracker(props.token, {symbol, strategyName}),
            {
                staleTime: 300000, // stale after 5 minutes

                keepPreviousData: true,
                refetchInterval: 300000,
                refetchIntervalInBackground: true,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            },
        );
        console.log({
            data
        })
        if (symbol && strategyName) {
            return (
                <Page
                    className="StrategyTrackerPage"
                    title="Strategy Tracker"
                    breadcrumbs={[{name: 'Strategy Tracker ', active: false}]}
                >
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            {
                                data && data.data ? <StrategyTrack
                                    loader={isLoading}
                                    strategies={data.data}
                                    cardTitle="Strategy"
                                    trackStrategy={(symbolName, strategyName) => {
                                    }}
                                    tableFixed={false}
                                /> : null
                            }
                        </Col>
                    </Row>
                </Page>
            );
        }


    };
    export default connect(mapStateToProps)(withRouter(StrategyTrackerPage));


    const headers = [
        {
            label: 'tracker',
            title: 'track it',
        },
        {
            label: 'SN',
            title: 'SN',
        },
        {
            label: 'Name',
            title: 'Name',
        },
        {
            label: 'E.No',
            title: 'Number of Entry',
        },
        {
            label: 'W.No',
            title: 'Number of Winning',
        },
        {
            label: 'L.No',
            title: 'Number of Losing',
        },
        {
            label: 'B.D',
            title: 'Buy Date',
        },
        {
            label: 'B.R',
            title: 'Buy (Rs)',
        },
        {
            label: 'S.D',
            title: 'Sell Date',
        },
        {
            label: 'S.R',
            title: 'Sell (Rs)',
        },
        {
            label: 'SP/L(%)',
            title: 'Profit/Loss(%)',
        },
        {
            label: 'Dur',
            title: 'Duration',
        },
        {
            label: 'P/L(%)',
            title: 'Entry Exit Profit/Loss(%)',
        },

        {
            label: 'W.R',
            title: 'Winning positions ratio',
        },
        {
            label: 'M.D',
            title: 'Maximum drawdown',
        },
        {
            label: 'R.O.M.D',
            title: 'Return over maximum drawdown',
        },
        {
            label: 'NOP',
            title: 'Number of positions',
        },

        {
            label: 'NOB',
            title: 'Number of bars',
        },
    ];
    const StrategyTrack = ({loader, cardTitle, strategies, trackStrategy, tableFixed = true}) => {
        console.log(trackStrategy);
        const [filterText, setFilterText] = useState('');
        const filterItems = strategies && strategies.filter(s =>
            s.strategyName.toLowerCase().includes(filterText.toLowerCase()),
        );

        function calculateAccuracy(numWinningTrades, totalNumTrades) {
            return ((numWinningTrades / totalNumTrades) * 100).toFixed(2);
        }

        const getProfitOrLoss = (position, ltp) => {
            console.log(position, ltp);
            return position.exit ? Number(
                    ((position.exit.price -
                            position.entry.price) /
                        position.exit.price) *
                    100,
                ).toFixed(1) :
                Number(
                    ((ltp -
                            position.entry.price) /
                        ltp) *
                    100,
                ).toFixed(1)
        }
        const getProfitOrLossAmount = (position, ltp) => {
            console.log(position, ltp);
            return position.exit ? Number((position.exit.price -
                    position.entry.price)
                ).toFixed(1) :
                Number(
                    ltp -
                    position.entry.price
                ).toFixed(1)
        }

        return (
            <Card className="mt-1">
                <CardHeader>

                </CardHeader>
                <CardBody>
                    {loader ? (
                        <Loader/>
                    ) : (
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>

                                <Row>
                                    <Col lg={12}>
                                        {filterItems && filterItems.length ? (
                                            filterItems.map(
                                                (
                                                    {
                                                        positions,
                                                        lastEntry,
                                                        strategyName,
                                                        numberOfPosition,
                                                        symbolName,
                                                        totalReturn,
                                                        numberOfBar,
                                                        avgReturnPerBar,
                                                        winningPositionRatio,
                                                        maximumDrawDown,
                                                        returnOverMaximumDrawDown,
                                                        totalTransactionCost,
                                                        buyHoldReturnCriteria,
                                                        versusBuyAndHold,
                                                        entryExitPercentage,
                                                        ltp,
                                                        additionalInformation
                                                    },
                                                    index,
                                                ) => {
                                                    const entryOrBreakEven = positions.filter(
                                                        p => p.netProfitOrLoss == 0,
                                                    ).length;
                                                    const winning = positions.filter(
                                                        p => p.netProfitOrLoss > 0,
                                                    ).length;
                                                    const losing = positions.filter(
                                                        p => p.netProfitOrLoss < 0,
                                                    ).length;
                                                    const accuracyWinning = calculateAccuracy(winning, positions.length - 1);
                                                    const accuracyLosing = calculateAccuracy(losing, positions.length - 1);

                                                    return (
                                                        <Card>
                                                            <CardHeader> Strategy Name: {strategyName} for {symbolName}
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={4}>
                                                                        <ListGroup flush className="pivot-analysis">
                                                                            <ListGroupItem
                                                                                className="d-flex justify-content-between bd-highlight">
                                                                                Entry Position : {entryOrBreakEven}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className="d-flex justify-content-between bd-highlight">
                                                                                Winning Position : {winning} out
                                                                                of {numberOfPosition} ({accuracyWinning} %)
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className="d-flex justify-content-between bd-highlight">
                                                                                Losing Position : {losing} out
                                                                                of {numberOfPosition} ({accuracyLosing} %)
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  ' +
                                                                                    (entryExitPercentage >= 80
                                                                                        ? 'text-white bg-success'
                                                                                        : 'bg-default')
                                                                                }>

                                                                                {entryExitPercentage > 0 ? `Profit ${Number(entryExitPercentage).toFixed(0)}` : `Loss ${Number(entryExitPercentage).toFixed(0)}`}{'%'}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  ' +
                                                                                    (winningPositionRatio >= 0.8
                                                                                        ? 'text-white bg-success'
                                                                                        : 'bg-default')
                                                                                }
                                                                            >
                                                                                Winning Ratio
                                                                                : {Number(winningPositionRatio).toFixed(1)}{' '}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  '

                                                                                }
                                                                            >
                                                                                Total Return
                                                                                : {Number(totalReturn).toFixed(1)}{' '}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  '

                                                                                }
                                                                            >
                                                                                Average Return per bar
                                                                                : {Number(avgReturnPerBar).toFixed(1)}{' '}
                                                                            </ListGroupItem>

                                                                        </ListGroup>
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        <div className={"alert alert-default"} dangerouslySetInnerHTML={{__html:additionalInformation}}>

                                                                        </div>
                                                                    </Col>

                                                                    <Col lg={4}>
                                                                        <ListGroup flush className="pivot-analysis">

                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  ' +
                                                                                    (winningPositionRatio >= 0.8
                                                                                        ? 'text-white bg-success'
                                                                                        : 'bg-default')
                                                                                }
                                                                            >
                                                                                Risk-to-Reward
                                                                                Ratio: {Number(maximumDrawDown).toFixed(1)}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  ' +
                                                                                    (winningPositionRatio >= 0.8
                                                                                        ? 'text-white bg-success'
                                                                                        : 'bg-default')
                                                                                }
                                                                            >
                                                                                Reward-to-Risk
                                                                                Ratio: {Number(returnOverMaximumDrawDown).toFixed(
                                                                                1,
                                                                            )}{' '}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  '
                                                                                }
                                                                            >
                                                                                Number of Bar : {numberOfBar}{' '}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  '
                                                                                }
                                                                            >
                                                                                Total Transaction Cost :
                                                                                Rs. {Number(totalTransactionCost).toFixed(0)}{' '}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  '
                                                                                }
                                                                            >
                                                                                Buy Hold Return Criteria
                                                                                : {Number(buyHoldReturnCriteria).toFixed(0)}{' '}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  '
                                                                                }
                                                                            >
                                                                                Versus Buy & Hold
                                                                                : {Number(versusBuyAndHold).toFixed(0)}{' '}
                                                                            </ListGroupItem>
                                                                            <ListGroupItem
                                                                                className={
                                                                                    'align-left  '
                                                                                }
                                                                            >
                                                                                Last Trade Price : Rs. {ltp}
                                                                            </ListGroupItem>
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={5}>
                                                                        <h5>Entry</h5>
                                                                    </Col>
                                                                    <Col lg={2}>
                                                                        <h5>Trade Information</h5>
                                                                    </Col>
                                                                    <Col lg={5}>
                                                                        <h5>Exit</h5>
                                                                    </Col>
                                                                </Row>

                                                                {
                                                                    positions.map((position, inde) => (
                                                                        <Row>
                                                                            <Col lg={5}>

                                                                                {
                                                                                    <IconWidget
                                                                                        bgColor={
                                                                                            'primary'
                                                                                        }
                                                                                        icon={
                                                                                            MdAlarmOn
                                                                                        }
                                                                                        title={`Entry at Rs.${Math.round(position.entry.price)}`}
                                                                                        subtitle={
                                                                                            `Date : ${moment(
                                                                                                position.entry.date.split('[')[0],
                                                                                            ).format('DD, MMM Y')}`
                                                                                        }
                                                                                    />
                                                                                }
                                                                            </Col>
                                                                            <Col lg={2}>
                                                                                {
                                                                                    !position.exit && inde === 0 ?
                                                                                        <ListGroup flush>
                                                                                            <ListGroupItem
                                                                                                className={
                                                                                                    getProfitOrLoss(position, ltp) > 0 ? 'bg-primary text-white' : 'bg-danger text-white'
                                                                                                }>
                                                                                                {getProfitOrLoss(position, ltp) > 0 ? `Pot. Profit : ${getProfitOrLoss(position, ltp)}%` : `Potential Loss : ${getProfitOrLoss(position, ltp)}%`}
                                                                                            </ListGroupItem>
                                                                                            <ListGroupItem
                                                                                                className={
                                                                                                    getProfitOrLossAmount(position, ltp) > 0 ? 'bg-primary text-white' : 'bg-danger text-white'
                                                                                                }>
                                                                                                {getProfitOrLossAmount(position, ltp) > 0 ? `Pot. Profit Rs. : ${getProfitOrLossAmount(position, ltp)} / share` : `Pot. Loss : Rs. ${getProfitOrLossAmount(position, ltp)} / share`}
                                                                                            </ListGroupItem>
                                                                                            <ListGroupItem className={
                                                                                                getProfitOrLossAmount(position, ltp) > 0 ? 'bg-primary text-white' : 'bg-danger text-white'
                                                                                            }>
                                                                                                Pot. Duration : {moment(

                                                                                            ).diff(
                                                                                                moment(
                                                                                                    position.entry.date.split('[')[0],
                                                                                                ),
                                                                                                'days',
                                                                                            )} days
                                                                                            </ListGroupItem>
                                                                                        </ListGroup>
                                                                                        :
                                                                                        <ListGroup flush>
                                                                                            <ListGroupItem
                                                                                                className={
                                                                                                    getProfitOrLoss(position, ltp) > 0 ? 'bg-primary text-white' : 'bg-danger text-white'
                                                                                                }>
                                                                                                {getProfitOrLoss(position, ltp) > 0 ? `Profit : ${getProfitOrLoss(position, ltp)}%` : `Loss : ${getProfitOrLoss(position, ltp)}%`}
                                                                                            </ListGroupItem>
                                                                                            <ListGroupItem
                                                                                                className={
                                                                                                    getProfitOrLossAmount(position, ltp) > 0 ? 'bg-primary text-white' : 'bg-danger text-white'
                                                                                                }>
                                                                                                {getProfitOrLossAmount(position, ltp) > 0 ? `Profit Rs. : ${getProfitOrLossAmount(position, ltp)} / share` : `Loss : Rs. ${getProfitOrLossAmount(position, ltp)} / share`}
                                                                                            </ListGroupItem>
                                                                                            <ListGroupItem className={
                                                                                                getProfitOrLossAmount(position, ltp) > 0 ? 'bg-primary text-white' : 'bg-danger text-white'
                                                                                            }>
                                                                                                Duration : {moment(
                                                                                                position.exit.date.split('[')[0],
                                                                                            ).diff(
                                                                                                moment(
                                                                                                    position.entry.date.split('[')[0],
                                                                                                ),
                                                                                                'days',
                                                                                            )} days
                                                                                            </ListGroupItem>
                                                                                        </ListGroup>
                                                                                }
                                                                            </Col>
                                                                            <Col lg={5}>

                                                                                {
                                                                                    !position.exit && inde === 0 ?
                                                                                        <IconWidget
                                                                                            bgColor={
                                                                                                'primary'
                                                                                            }
                                                                                            icon={
                                                                                                MdLightbulbOutline
                                                                                            }
                                                                                        /> : <IconWidget
                                                                                            bgColor={
                                                                                                getProfitOrLoss(position, ltp) > 0
                                                                                                    ? 'primary' : 'danger'
                                                                                            }
                                                                                            icon={
                                                                                                getProfitOrLoss(position, ltp) > 0
                                                                                                    ? MdThumbUp
                                                                                                    : MdThumbDown
                                                                                            }
                                                                                            title={`Exit at Rs.${Math.round(position.exit.price)}`}
                                                                                            subtitle={
                                                                                                `Date : ${moment(
                                                                                                    position.exit.date.split('[')[0],
                                                                                                ).format('DD, MMM Y')}`
                                                                                            }
                                                                                        />
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    ))
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    );


                                                },
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="">
                                                    No Record(s)
                                                </td>
                                            </tr>
                                        )}
                                        {/*<span>Typical</span>*/}
                                        {/*<span>{`Rs. ${Number(technicalIndicator[0].typical).toFixed(*/}
                                        {/*    2,*/}
                                        {/*)}`}</span>*/}
                                        <p>
                                            Long selling, also known as "going long," is the most common type of investment
                                            strategy. When an investor takes a long position, they are buying an asset, such
                                            as a stock, with the expectation that its value will increase over time. If the
                                            price of the asset goes up, the investor can sell it for a profit.

                                            Short selling, on the other hand, involves borrowing an asset, such as a stock,
                                            from a broker and selling it with the hope that its price will go down. The
                                            investor expects to buy the asset back at a lower price, return it to the
                                            broker, and profit from the difference. Short selling is often considered a
                                            riskier investment strategy because losses can be unlimited if the asset's price
                                            rises instead of falling.
                                        </p>
                                    </Col>
                                </Row>


                                {/*<div className={*/}
                                {/*    tableFixed ? "tableFixHead" : "table-responsive"*/}
                                {/*}>*/}
                                {/*    <Table size="sm">*/}
                                {/*        <thead className={'thead-dark'}>*/}
                                {/*        <tr className="text-capitalize align-left">*/}
                                {/*            {headers.map((item, index) => (*/}
                                {/*                <th key={index} title={item.title}>*/}
                                {/*                    {item.label}*/}
                                {/*                </th>*/}
                                {/*            ))}*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        {filterItems && filterItems.length ? (*/}
                                {/*            filterItems.map(*/}
                                {/*                (*/}
                                {/*                    {*/}
                                {/*                        positions,*/}
                                {/*                        lastEntry,*/}
                                {/*                        strategyName,*/}
                                {/*                        numberOfPosition,*/}
                                {/*                        symbolName,*/}
                                {/*                        totalReturn,*/}
                                {/*                        numberOfBar,*/}
                                {/*                        avgReturnPerBar,*/}
                                {/*                        winningPositionRatio,*/}
                                {/*                        maximumDrawDown,*/}
                                {/*                        returnOverMaximumDrawDown,*/}
                                {/*                        totalTransactionCost,*/}
                                {/*                        buyHoldReturnCriteria,*/}
                                {/*                        versusBuyAndHold,*/}
                                {/*                        entryExitPercentage,*/}
                                {/*                    },*/}
                                {/*                    index,*/}
                                {/*                ) => {*/}
                                {/*                    const entryOrBreakEven = positions.filter(*/}
                                {/*                        p => p.netProfitOrLoss == 0,*/}
                                {/*                    ).length;*/}
                                {/*                    const winning = positions.filter(*/}
                                {/*                        p => p.netProfitOrLoss > 0,*/}
                                {/*                    ).length;*/}
                                {/*                    const losing = positions.filter(*/}
                                {/*                        p => p.netProfitOrLoss < 0,*/}
                                {/*                    ).length;*/}
                                {/*                    const accuracyWinning = calculateAccuracy(winning, positions.length - 1);*/}
                                {/*                    const accuracyLosing = calculateAccuracy(losing, positions.length - 1);*/}

                                {/*                    return positions.map((position, inde) => (*/}
                                {/*                        <tr key={inde}>*/}
                                {/*                            {inde === 0 ? (*/}
                                {/*                                <React.Fragment>*/}
                                {/*                                    {*/}
                                {/*                                        <td>*/}
                                {/*                                            <button className={'btn btn-sm btn-primary'}*/}
                                {/*                                                    onClick={() => trackStrategy(strategyName)}>Track*/}
                                {/*                                                it?*/}
                                {/*                                            </button>*/}
                                {/*                                        </td>*/}
                                {/*                                    }*/}

                                {/*                                    <td>*/}
                                {/*                                        {index + 1}.{inde + 1}*/}
                                {/*                                    </td>*/}
                                {/*                                    <td>*/}
                                {/*                                        <strong>{strategyName}</strong>*/}
                                {/*                                    </td>*/}
                                {/*                                    <td>{entryOrBreakEven}</td>*/}
                                {/*                                    <td>{winning}( {accuracyWinning} %)</td>*/}
                                {/*                                    <td>{losing} ( {accuracyLosing} %)</td>*/}
                                {/*                                    <td>*/}
                                {/*                                        {moment(*/}
                                {/*                                            position.entry.date.split('[')[0],*/}
                                {/*                                        ).format('DD, MMM Y')}{' '}*/}
                                {/*                                    </td>*/}
                                {/*                                    <td>{Math.round(position.entry.price)}</td>*/}
                                {/*                                    {position.exit ? (*/}
                                {/*                                        <React.Fragment>*/}
                                {/*                                            <td>*/}
                                {/*                                                {moment(*/}
                                {/*                                                    position.exit.date.split('[')[0],*/}
                                {/*                                                ).format('DD, MMM Y')}*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>{Math.round(position.exit.price)}</td>*/}

                                {/*                                            <td>*/}
                                {/*                                                {Number(*/}
                                {/*                                                    ((position.exit.price -*/}
                                {/*                                                            position.entry.price) /*/}
                                {/*                                                        position.exit.price) **/}
                                {/*                                                    100,*/}
                                {/*                                                ).toFixed(1)}*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                {moment(*/}
                                {/*                                                    position.exit.date.split('[')[0],*/}
                                {/*                                                ).diff(*/}
                                {/*                                                    moment(*/}
                                {/*                                                        position.entry.date.split('[')[0],*/}
                                {/*                                                    ),*/}
                                {/*                                                    'days',*/}
                                {/*                                                )}*/}
                                {/*                                            </td>*/}
                                {/*                                        </React.Fragment>*/}
                                {/*                                    ) : (*/}
                                {/*                                        <React.Fragment>*/}
                                {/*                                            <td>Not Found</td>*/}
                                {/*                                            <td>-</td>*/}
                                {/*                                            <td>-</td>*/}
                                {/*                                            <td>-</td>*/}
                                {/*                                        </React.Fragment>*/}
                                {/*                                    )}*/}
                                {/*                                    <td>*/}
                                {/*                                        {Number(entryExitPercentage).toFixed(2)}{' '}*/}
                                {/*                                    </td>*/}
                                {/*                                    {' '}*/}
                                {/*                                    <td*/}
                                {/*                                        className={*/}
                                {/*                                            'align-left  ' +*/}
                                {/*                                            (winningPositionRatio >= 0.7*/}
                                {/*                                                ? 'text-white bg-success'*/}
                                {/*                                                : 'bg-default')*/}
                                {/*                                        }*/}
                                {/*                                    >*/}
                                {/*                                        {Number(winningPositionRatio).toFixed(1)}{' '}*/}
                                {/*                                    </td>*/}
                                {/*                                    <td>{Number(maximumDrawDown).toFixed(1)} </td>*/}
                                {/*                                    {' '}*/}
                                {/*                                    <td>*/}
                                {/*                                        {Number(returnOverMaximumDrawDown).toFixed(*/}
                                {/*                                            1,*/}
                                {/*                                        )}{' '}*/}
                                {/*                                    </td>*/}
                                {/*                                    {' '}*/}
                                {/*                                    <td>{numberOfPosition} </td>*/}
                                {/*                                    <td>{numberOfBar} </td>*/}
                                {/*                                </React.Fragment>*/}
                                {/*                            ) : (*/}
                                {/*                                <React.Fragment>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td>*/}
                                {/*                                        {index + 1}.{inde + 1}*/}
                                {/*                                    </td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}

                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td>*/}
                                {/*                                        {moment(*/}
                                {/*                                            position.entry.date.split('[')[0],*/}
                                {/*                                        ).format('DD, MMM Y')}{' '}*/}
                                {/*                                    </td>*/}
                                {/*                                    <td>{Math.round(position.entry.price)}</td>*/}
                                {/*                                    {position.exit ? (*/}
                                {/*                                        <React.Fragment>*/}
                                {/*                                            <td>*/}
                                {/*                                                {moment(*/}
                                {/*                                                    position.exit.date.split('[')[0],*/}
                                {/*                                                ).format('DD, MMM Y')}*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>{Math.round(position.exit.price)}</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                {Number(*/}
                                {/*                                                    ((position.exit.price -*/}
                                {/*                                                            position.entry.price) /*/}
                                {/*                                                        position.exit.price) **/}
                                {/*                                                    100,*/}
                                {/*                                                ).toFixed(1)}*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                {moment(*/}
                                {/*                                                    position.exit.date.split('[')[0],*/}
                                {/*                                                ).diff(*/}
                                {/*                                                    moment(*/}
                                {/*                                                        position.entry.date.split('[')[0],*/}
                                {/*                                                    ),*/}
                                {/*                                                    'days',*/}
                                {/*                                                )}*/}
                                {/*                                            </td>*/}
                                {/*                                        </React.Fragment>*/}
                                {/*                                    ) : (*/}
                                {/*                                        <React.Fragment>*/}
                                {/*                                            <td>-</td>*/}
                                {/*                                            <td>-</td>*/}
                                {/*                                            <td>-</td>*/}
                                {/*                                        </React.Fragment>*/}
                                {/*                                    )}*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                    <td></td>*/}
                                {/*                                </React.Fragment>*/}
                                {/*                            )}*/}
                                {/*                        </tr>*/}
                                {/*                    ));*/}
                                {/*                },*/}
                                {/*            )*/}
                                {/*        ) : (*/}
                                {/*            <tr>*/}
                                {/*                <td colSpan="6" className="">*/}
                                {/*                    No Record(s)*/}
                                {/*                </td>*/}
                                {/*            </tr>*/}
                                {/*        )}*/}
                                {/*        </tbody>*/}
                                {/*    </Table>*/}
                                {/*</div>*/}
                            </Col>
                        </Row>
                    )}
                </CardBody>
            </Card>
        );
    };

