import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DaywiseAnalysis, Loader } from 'components';
import { viewTopBrokerHoldingByDurationByBrokerCode } from 'redux/TopBrokerHoldingByDuration/topbrokerholdingbyduration.actions';
import { Row, Card, CardBody, CardHeader, Col } from 'reactstrap';
const BrokerHoldingByDuration = ({
  loader,
  data,
  setLoaderState,
  token,
  processed,
  brokerCode,
  viewTopBrokerHoldingByDurationByBrokerCode,
  loaderStr,
  analysisClick,
  brokerClick,
}) => {
  useEffect(() => {
    const callApi = async () => {
      console.log('here at calling api 2');
      setLoaderState(loaderStr, true);
      await viewTopBrokerHoldingByDurationByBrokerCode({ token, brokerCode });
      setLoaderState(loaderStr, false);
      console.log('here at calling api 2');
    };
    callApi();
  }, [processed]);

  return (
    <Card className="mt-1">
      <CardHeader>Top Broker Holding By Duration</CardHeader>
      <CardBody>
        {loader ? (
          <Loader />
        ) : (
          <Row className="m-2">
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last One Days'}
                days={data['one']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
            </Col>
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last Two Days'}
                days={data['two']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
              <Col sm={12} xs={12}>
                <DaywiseAnalysis
                  showSymbol={true}
                  label={'Last Three Days'}
                  days={data['three']}
                  analysisClick={analysisClick}
                  brokerClick={brokerClick}
                />
              </Col>
            </Col>
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last Seven Days'}
                days={data['seven']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
            </Col>
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last Fourteen Days'}
                days={data['fourteen']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
            </Col>
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last One Month'}
                days={data['month']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
            </Col>
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last Three Month'}
                days={data['threemonth']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
            </Col>
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last Six Month'}
                days={data['sixmonth']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
            </Col>
            <Col sm={12} xs={12}>
              <DaywiseAnalysis
                showSymbol={true}
                label={'Last One Year'}
                days={data['oneyear']}
                analysisClick={analysisClick}
                brokerClick={brokerClick}
              />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default connect(null, {
  viewTopBrokerHoldingByDurationByBrokerCode,
})(withRouter(BrokerHoldingByDuration));
