import React from 'react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Row} from 'reactstrap';
import {
    Page,
    TechnicalIndicatorHistory,
    Strategy,
    TodayScriptTechnicalIndicator,
} from '../components';
import {getColor} from 'utils/colors';
import _ from 'lodash';
import HRNumbers from 'human-readable-numbers';
import moment from 'moment';
import {addStrategyTracker, getTechnicalAnalysisBySymbol} from 'api/realtime';
import {useQuery} from 'react-query';
import Col from 'reactstrap/lib/Col';
import {useMutation} from 'react-query';

// class TechnicalAnalysisPage extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       loader: false,
//       realTimePortfolioAnalysisLoader: false,
//       overallTopHoldingBrokerLoader: false,
//       technicalIndicatorTodayLoader: false,
//       naveragePriceLoader: false,
//       processed: 0,
//       stockHoldingCounter: 0,
//       symbol: '',
//       qtydateQuery: '',
//     };
//   }

//   render() {
//     const primaryColor = getColor('primary');
//     const secondaryColor = getColor('secondary');
//     return (
//       <Page
//         className="TechnicalAnalysisPage"
//         title="Technical Analysis"
//         breadcrumbs={[
//           { name: 'Technical Analysis', active: false },
//           {
//             name: this.props.match.params.symbol,
//             active: true,
//           },
//         ]}
//       >
//         <TechnicalStrategy
//           token={this.props.token}
//           symbol={this.props.match.params.symbol}
//         />
//       </Page>
//     );
//   }
// }

function mapStateToProps(state) {
    return {
        token: state.auth.authenticated,
    };
}

// export default connect(mapStateToProps)(withRouter(TechnicalAnalysisPage));

const TechnicalAnalysisPage = props => {
    console.log(props, "props");
    const {data, isError, error, isLoading, isFetched} = useQuery(
        ['TechnicalAnalysisBySymbol', props.match.params.symbol],
        () => getTechnicalAnalysisBySymbol(props.token, props.match.params.symbol),
        {
            staleTime: 300000, // stale after 5 minutes

            keepPreviousData: true,
            refetchInterval: 300000,
            refetchIntervalInBackground: true,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );
    const onSuccess = (symbol, strategyName) => {
        console.log(`strategy-tracker?symbol=${symbol}&strategyName=${strategyName}`);
        props.history.push(`../../strategy-tracker?symbol=${symbol}&strategyName=${strategyName}`);
    };
    const trackerMutation = useMutation((data) => addStrategyTracker(props.token, data));
    const trackStrategy = async (strategyName) => {
        console.log("trackStrategy")


        await trackerMutation.mutate({symbol: props.match.params.symbol, strategyName}, {
            onSuccess: () => onSuccess(props.match.params.symbol, strategyName)
        });
    }
    return (
        <Page
            className="TechnicalAnalysisPage"
            title="Technical Analysis"
            breadcrumbs={[
                {name: 'Technical Analysis', active: false},
                {
                    name: props.match.params.symbol,
                    active: true,
                },
            ]}
        >
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <Row className="m-2">
                    {/* <Col lg={12}>
                        <TodayScriptTechnicalIndicator
                            loader={isLoading}
                            technicalIndicator={data.data.ta}
                        />
                    </Col> */}
                    {/* <Col lg={12}>
            <Strategy
              loader={isLoading}
              strategies={data.data.strategies}
              cardTitle="Live Strategy"
              headers={['Name', 'Position', 'Summary']}
            />
          </Col> */}
                    <Col lg={12}>
                        <TechnicalIndicatorHistory
                            loader={isLoading}
                            technicalIndicators={data.data.ta}
                            cardTitle="technical analysis history"
                            headers={[
                                {
                                    name: 'Date',
                                    selector: 'date',
                                    sortable: true,
                                    cell: row => (
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {moment(row.date).format('DD, MMM,Y h:mm:ss')}
                                        </div>
                                    ),
                                },
                                {
                                    name: 'Close Price',
                                    selector: 'closePrice',
                                    sortable: true,
                                },
                                {
                                    name: 'Open Price',
                                    selector: 'openPrice',
                                    sortable: true,
                                },
                                {
                                    name: 'Low Price',
                                    selector: 'lowPrice',
                                    sortable: true,
                                },
                                {
                                    name: 'High Price',
                                    selector: 'highPrice',
                                    sortable: true,
                                },
                                {
                                    name: 'Acc/Dis',
                                    selector: 'accumulationDistribution',
                                    sortable: true,
                                    cell: row => {
                                        return <div>{HRNumbers.toHumanString(row.accumulationDistribution)}
                                            {row.accumulationDistribution > 0 ? " (Acc)" : " (Dis)"}
                                        </div>
                                    },
                                },
                                {
                                    name: 'SuperTrend',
                                    selector: 'supertrendBuy',
                                    sortable: true,
                                    conditionalCellStyles: [
                                        {
                                            when: row => row.supertrendBuy==="Buy",
                                            style: {
                                                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                                                color: 'white',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            },
                                        },
                                        {
                                            when: row => row.supertrendBuy==="Sell",
                                            style: {
                                                backgroundColor: 'rgba(255, 99, 71)',
                                                color: 'white',
                                                '&:hover': {
                                                    cursor: 'not-allowed',
                                                },
                                            },
                                        },
                                    ],
                                },
                                {
                                    name: 'volume',
                                    selector: 'volume',
                                    sortable: true,
                                    cell: row => <div>{HRNumbers.toHumanString(row.volume)}</div>,
                                },
                                {
                                    name: 'rsi',
                                    selector: 'rsi',
                                    sortable: true,
                                    conditionalCellStyles: [
                                        {
                                            when: row => row.rsi < 30,
                                            style: {
                                                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                                                color: 'white',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            },
                                        },
                                        {
                                            when: row => row.rsi >= 80,
                                            style: {
                                                backgroundColor: 'rgba(255, 99, 71)',
                                                color: 'white',
                                                '&:hover': {
                                                    cursor: 'not-allowed',
                                                },
                                            },
                                        },
                                    ],
                                },
                                {
                                    name: 'willianSR',
                                    selector: 'willianSR',
                                    sortable: true,
                                    conditionalCellStyles: [
                                        {
                                            when: row => row.willianSR <= -80,
                                            style: {
                                                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                                                color: 'white',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            },
                                        },
                                        {
                                            when: row => row.willianSR >= -20,
                                            style: {
                                                backgroundColor: 'rgba(255, 99, 71)',
                                                color: 'white',
                                                '&:hover': {
                                                    cursor: 'not-allowed',
                                                },
                                            },
                                        },
                                    ],
                                },
                                {
                                    name: 'typical',
                                    selector: 'typical',
                                    sortable: true,
                                },
                                {
                                    name: 'variation',
                                    selector: 'variation',
                                    sortable: true,
                                },
                                ,
                                {
                                    name: 'ppo',
                                    selector: 'ppo',
                                    sortable: true,
                                },
                                {
                                    name: 'roc',
                                    selector: 'roc',
                                    sortable: true,
                                },
                                {
                                    name: 'atr',
                                    selector: 'atr',
                                    sortable: true,
                                },
                                {
                                    name: 'Sd.',
                                    selector: 'standardDeviation',
                                    sortable: true,
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={12}>
                        <TechnicalIndicatorHistory
                            loader={isLoading}
                            technicalIndicators={data.data.ta}
                            cardTitle="technical analysis history"
                            headers={[
                                {
                                    name: 'Date',
                                    selector: 'date',
                                    sortable: true,
                                    cell: row => (
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {moment(row.date).format('DD, MMM,Y h:mm:ss')}
                                        </div>
                                    ),
                                },
                                {
                                    name: 'Close Price',
                                    selector: 'closePrice',
                                    sortable: true,
                                },

                                {
                                    name: 'sma5',
                                    selector: 'sma5',
                                    sortable: true,
                                },
                                {
                                    name: 'sma20',
                                    selector: 'sma20',
                                    sortable: true,
                                },
                                {
                                    name: 'ema5',
                                    selector: 'ema5',
                                    sortable: true,
                                },
                                {
                                    name: 'ema20',
                                    selector: 'ema20',
                                    sortable: true,
                                },
                                {
                                    name: 'support3',
                                    selector: 'support3',
                                    sortable: true,
                                },
                                {
                                    name: 'support2',
                                    selector: 'support2',
                                    sortable: true,
                                },
                                {
                                    name: 'support1',
                                    selector: 'support1',
                                    sortable: true,
                                },
                                {
                                    name: 'pivot',
                                    selector: 'pivot',
                                    sortable: true,
                                },
                                {
                                    name: 'resistance1',
                                    selector: 'resistance1',
                                    sortable: true,
                                },
                                {
                                    name: 'resistance2',
                                    selector: 'resistance2',
                                    sortable: true,
                                },
                                {
                                    name: 'resistance3',
                                    selector: 'resistance3',
                                    sortable: true,
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={12}>
                        <Strategy
                            loader={isLoading}
                            strategies={data.data.uptoStrategies}
                            cardTitle="Upto Yesterday Strategy"
                            trackStrategy={(symbolName, strategyName) => trackStrategy(symbolName, strategyName)}
                        />
                    </Col>

                </Row>
            )}
        </Page>
    );
};
export default connect(mapStateToProps)(withRouter(TechnicalAnalysisPage));
