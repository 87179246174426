import React from 'react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Col, Row} from 'reactstrap';
import {
    Page,
    TechnicalIndicatorHistory,
    Strategy,
    TodayScriptTechnicalIndicator,
} from '../components';
import {getColor} from 'utils/colors';
import _ from 'lodash';
import HRNumbers from 'human-readable-numbers';
import moment from 'moment';
import {getTechnicalAnalysisBySymbol} from 'api/realtime';
import {useQuery} from 'react-query';
import {SummaryTable} from 'components/WatchListTable';

function mapStateToProps(state) {
    return {
        token: state.auth.authenticated,
    };
}

// export default connect(mapStateToProps)(withRouter(TechnicalAnalysisPage));

const StrategySummaryPage = props => {
    const technicalClick = symbol => {
        props.history.push('technical/' + symbol);
    };
    const analysisClick = symbol => {
        props.history.push('analysis/' + symbol);
    };
    return (
        <Page
            className="StrategySummaryPage"
            title="Strategy Summary"
            breadcrumbs={[{name: 'Strategy Summary ', active: false}]}
        >
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <SummaryTable
                        key="summary"
                        token={props.token}
                        headers={[
                            'Track',
                            'SN.',
                            'Symbol',
                            'Sector',
                            'LTP',
                            'name',
                            'MD',
                            'RMD',
                            'W.Ratio',
                            'Return %',
                            'Price',
                            'PL(%)',
                            'E',
                            'W',
                            'L',
                            'Avg.D',
                            'Date',
                            'Actions',
                        ]}
                        analysisClick={analysisClick}
                        technicalClick={technicalClick}
                        tableTitle="Startegy Summary"
                    />
                    <p>
                        <span>MD : Maximum Drawdown</span>
                        <br/>
                        <span>RMD : Return on Maximum Drawdown</span>
                        <br/>
                        <span>W.Ratio : Winning Ratio</span>
                        <br/>
                        <span>PL : Profit & Loss</span>
                        <br/>
                        <span>E : Entry</span>
                        <br/>
                        <span>W : Winning Position</span>
                        <br/>
                        <span>L : Losing Position</span>
                        <br/>
                        <span>Avg.D : Average Return Days</span>
                    </p>
                </Col>
            </Row>
        </Page>
    );
};
export default connect(mapStateToProps)(withRouter(StrategySummaryPage));
