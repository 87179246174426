import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { viewWatchList } from 'redux/WatchList/watchlist.actions';
import { Loader } from '../index';
import SummaryTable from '../WatchListTable/summaryTable';
import WatchListTable from '../WatchListTable/WatchListTable';

const WatchList = ({
  loader,
  aboveFifty,
  aboveThirty,
  processed,
  token,
  setLoaderState,
  viewWatchList,
  summary,
  analysisClick,
}) => {
  useEffect(() => {
    const callApi = async () => {
      setLoaderState('watchListLoader', true);
      await viewWatchList({ token });
      setLoaderState('watchListLoader', false);
    };
    callApi();
  }, [processed]);
  return (
    <Card>
      <CardHeader>Drop Price Difference</CardHeader>
      <CardBody>
        {loader ? (
          <Loader />
        ) : (
          <div>
            {/* <SummaryTable
              key="summary"
              summary={summary}
              headers={['Symbol', 'Sector', 'Count', 'name']}
              analysisClick={analysisClick}
              tableTitle="Startegy Summary"
            /> */}
            <WatchListTable
              key="aboveFifty"
              pricedifferences={aboveFifty}
              headers={[
                'Symbol',
                'Drop (%)',
                'ltp',
                'n. low(%)',
                '52Wh',
                '52wl',
              ]}
              analysisClick={analysisClick}
              tableTitle="Above Fifty Percentage Drop"
            />
            <WatchListTable
              key="aboveThirty"
              pricedifferences={aboveThirty}
              headers={[
                'Symbol',
                'Drop (%)',
                'ltp',
                'n. low(%)',
                '52Wh',
                '52wl',
              ]}
              analysisClick={analysisClick}
              tableTitle="Above Thirty Percentage Drop"
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default connect(null, {
  viewWatchList,
})(withRouter(WatchList));
