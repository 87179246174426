import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { callTodayTechnicalAnalysis } from 'redux/Script/script.actions';
import { Loader, PivotAnalysis } from '..';

const TodayScriptTechnicalIndicator = ({ loader, technicalIndicator }) => {
  return loader ? (
    <Loader />
  ) : (
    <Card>
      <CardHeader> Today Technical Indicator</CardHeader>
      {technicalIndicator && technicalIndicator.length > 0 ? (
        <CardBody>
          <Row>
            <Col lg={6}>
              <ListGroup flush className="pivot-analysis">
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>Typical</span>
                  <span>{`Rs. ${Number(technicalIndicator[0].typical).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>Variation</span>
                  <span>{`${Number(technicalIndicator[0].variation).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>SMA(5)</span>
                  <span>{`T. Rs. ${Number(technicalIndicator[0].sma5).toFixed(
                    2,
                  )}`}</span>
                  <span>{`Y. Rs.${Number(technicalIndicator[1].sma5).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>SMA(20)</span>
                  <span>{`T. Rs. ${Number(technicalIndicator[0].sma20).toFixed(
                    2,
                  )}`}</span>
                  <span>{`Y. Rs.${Number(technicalIndicator[1].sma20).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>EMA(5)</span>
                  <span>{`T. Rs.${Number(technicalIndicator[0].ema5).toFixed(
                    2,
                  )}`}</span>
                  <span>{`Y. Rs.${Number(technicalIndicator[1].ema5).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>EMA(20)</span>
                  <span>{`T. Rs.${Number(technicalIndicator[0].ema20).toFixed(
                    2,
                  )}`}</span>
                  <span>{`Y. Rs.${Number(technicalIndicator[1].ema20).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span title="Percentage price oscillator">PPO</span>
                  <span>{`T. ${Number(technicalIndicator[0].ppo).toFixed(
                    2,
                  )}%`}</span>
                  <span>{`Y. ${Number(technicalIndicator[1].ppo).toFixed(
                    2,
                  )}%`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span title="Rate of change">ROC</span>
                  <span>{`T. ${Number(technicalIndicator[0].roc).toFixed(
                    2,
                  )}`}</span>
                  <span>{`Y. ${Number(technicalIndicator[1].roc).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>RSI (14)</span>
                  <span>{`T. ${Number(technicalIndicator[0].rsi).toFixed(
                    2,
                  )}`}</span>
                  <span>{`Y. ${Number(technicalIndicator[1].rsi).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>Williams %R</span>
                  <span>{`T. ${Number(technicalIndicator[0].willianSR).toFixed(
                    2,
                  )}`}</span>
                  <span>{`Y. ${Number(technicalIndicator[1].willianSR).toFixed(
                    2,
                  )}`}</span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between bd-highlight">
                  <span>Standard Deviation</span>
                  <span>{`T. ${Number(
                    technicalIndicator[0].standardDeviation,
                  ).toFixed(2)}`}</span>
                  <span>{`Y. ${Number(
                    technicalIndicator[1].standardDeviation,
                  ).toFixed(2)}`}</span>
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col lg={6}>
              <PivotAnalysis
                ypivotMovingAvgResponse={technicalIndicator[1]}
                tpivotMovingAvgResponse={technicalIndicator[0]}
              />
            </Col>
          </Row>
        </CardBody>
      ) : (
        <div></div>
      )}
    </Card>
  );
};
export default connect(null, {})(withRouter(TodayScriptTechnicalIndicator));
