import axios from 'axios';
import {topbrokerholdingbydurationTypes} from './topbrokerholdingbyduration.types';
export const URL = process.env.REACT_APP_ANALYZE_URL;
export const viewTopBrokerHoldingByDuration =({token}) => async dispatch => {
    try {
        let topBrokerHoldingByDuration;
        console.log('topBrokerHoldingByDuration');
        if (token) {
            topBrokerHoldingByDuration = await axios.get(
            `${URL}/realtime/analysis/topBrokerHoldingsByDuration`,
            {
              headers: { Authorization: `Bearer ` + token },
            },
          );
        }
    
        console.log('topBrokerHoldingByDuration', { topBrokerHoldingByDuration });
    
        if (topBrokerHoldingByDuration.data) {
          dispatch({
            type: topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_HOLDING_BY_DURATION,
            payload: topBrokerHoldingByDuration.data,
          });
        }
      } catch (e) {
        console.log({ e });
        if (e.response && e.response.data) {
          dispatch({
            type: topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_HOLDING_BY_DURATION_ERROR,
            payload: e.response.data.error,
          });
        }
      }
};

export const viewTopBrokerHoldingByDurationByBrokerCode =({token,brokerCode}) => async dispatch => {
    try {
        let topBrokerHoldingByDuration;
        console.log('topBrokerHoldingByDuration');
        if (token) {
            topBrokerHoldingByDuration = await axios.get(
                `${URL}/realtime/analysis/topBrokerHoldingsByDurationByCode/${brokerCode}`,
                {
                    headers: { Authorization: `Bearer ` + token },
                },
            );
        }

        console.log('topBrokerHoldingByDuration', { topBrokerHoldingByDuration });

        if (topBrokerHoldingByDuration.data) {
            dispatch({
                type: topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_CODE_HOLDING_BY_DURATION,
                payload: topBrokerHoldingByDuration.data,
            });
        }
    } catch (e) {
        console.log({ e });
        if (e.response && e.response.data) {
            dispatch({
                type: topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_CODE_HOLDING_BY_DURATION_ERROR,
                payload: e.response.data.error,
            });
        }
    }
};


export const viewDurationBySectorName =({token,sectorName}) => async dispatch => {
    try {
        let topBrokerHoldingByDuration;
        console.log('topBrokerHoldingByDuration');
        if (token) {
            topBrokerHoldingByDuration = await axios.get(
                `${URL}/realtime/analysis/topBrokerHoldingsByDurationBySectorName/${sectorName}`,
                {
                    headers: { Authorization: `Bearer ` + token },
                },
            );
        }

        console.log('topBrokerHoldingByDuration', { topBrokerHoldingByDuration });

        if (topBrokerHoldingByDuration.data) {
            dispatch({
                type: topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_SECTORNAME_HOLDING_BY_DURATION,
                payload: topBrokerHoldingByDuration.data,
            });
        }
    } catch (e) {
        console.log({ e });
        if (e.response && e.response.data) {
            dispatch({
                type: topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_SECTORNAME_HOLDING_BY_DURATION_ERROR,
                payload: e.response.data.error,
            });
        }
    }
};