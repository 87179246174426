import React from 'react';
import { connect } from 'react-redux';
import {
  StockHolding,
  TopVolumeStockHolding,
  WatchList,
  RealtimeTopHoldingBrokerByQuantity,
} from 'components';
import { withRouter } from 'react-router';
import { Row, Col } from 'reactstrap';

import {
  viewRealTimeByAmount,
  viewRealTimeByQuantity,
} from 'redux/Realtime/realtime.actions';
import { viewWatchList } from 'redux/WatchList/watchlist.actions';
import {
  viewStockHolding,
  viewTopVolumeStockHolding,
  viewTopSectorwiseStockHolding,
} from 'redux/StockHolding/stockholding.actions';
import { SummaryTable } from 'components/WatchListTable';
//TODO RESELECT
class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      realTimeTopHoldingBrokerLoader: false,
      stockHoldingLoader: false,
      topVolumeLoader: false,
      sectorWiseLoader: false,
      processed: 0,
      stockHoldingCounter: 0,
      watchListLoader: false,
    };
  }

  componentDidMount() { }

  refreshFloorsheetChanged = message => {
    this.setState({ processed: parseInt(message.body) });
  };
  refreshStockHoldingChanged = message => {
    const counter =
      message.body === 'completed'
        ? this.state.stockHoldingCounter + 1
        : this.state.stockHoldingCounter;
    console.log(counter);
    this.setState({
      stockHoldingCounter: counter,
    });
  };
  setLoaderState = (loaderName, loaderState) => {
    this.setState({ [loaderName]: loaderState });
  };
  analysisClick = symbol => {
    this.props.history.push('analysis/' + symbol);
  };
  technicalClick = symbol => {
    this.props.history.push('technical/' + symbol);
  };
  brokerClick = brokerCode => {
    this.props.history.push('broker/' + brokerCode);
  };
  onChangeOption = e => {
    console.log('console.log', e.target.value.startsWith('Select'));
    if (e.detail === 0 && !e.target.value.startsWith('Select')) {
      this.props.history.push('sector/' + e.target.value);
    }
  };
  onSubIndexAnalysis = e => {
    console.log('console.log', e.target.value.startsWith('Select'));
    if (e.detail === 0 && !e.target.value.startsWith('Select')) {
      this.props.history.push('technical/' + e.target.value);
    }
  };

  render() {
    return (
      <>
      
        <select onClick={this.onSubIndexAnalysis}>
          <option value="Banking SubIndex">Banking SubIndex</option>
          <option value="Hotels And Tourism Index">Hotels And Tourism Index</option>
          <option value="Others Index">Others Index</option>
          <option value="HydroPower Index">HydroPower Index</option>
          <option value="Development Bank Index">Development Bank Index</option>
          <option value="Manufacturing And Processing">Manufacturing And Processing</option>
          <option value="Sensitive Index">Sensitive Index</option>
          <option value="NEPSE Index">NEPSE Index</option>
          <option value="Non Life Insurance">Non Life Insurance</option>
          <option value="Finance Index">Finance Index</option>
          <option value="Trading Index">Trading Index</option>
          <option value="Float Index">Float Index</option>
          <option value="Sensitive Float Index">Sensitive Float Index</option>
          <option value="Microfinance Index">Microfinance Index</option>
          <option value="Life Insurance">Life Insurance</option>
          <option value="Mutual Fund">Mutual Fund</option>
          <option value="Investment Index">Investment Index</option>
        </select>

        <RealtimeTopHoldingBrokerByQuantity
          key="1"
          headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount','LTP']}
          token={this.props.token}
          analysisClick={this.analysisClick}
          brokerClick={this.brokerClick}
        />{' '}
        <RealtimeTopHoldingBrokerByQuantity
          key="2"
          headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount','LTP']}
          token={this.props.token}
          analysisClick={this.analysisClick}
          brokerClick={this.brokerClick}
          isQuantity={false}
        />
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <StockHolding
              loader={this.state.stockHoldingLoader}
              setLoaderState={this.setLoaderState}
              headers={[
                'SN.',
                'Code',
                'Broker',
                'Symbol',
                'Sector',
                'Holding',
                'Holding (%)',
                'Buy',
                'Sell',
                  'Ltp'
              ]}
              token={this.props.token}
              holdings={this.props.holdings}
              stockHoldingCounter={this.state.stockHoldingCounter}
              analysisClick={this.analysisClick}
              brokerClick={this.brokerClick}
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
          <select onClick={this.onChangeOption}>
          <option defaultChecked={true}>Select Sector Name</option>
          <option value="Non Life Insurance">Non Life Insurance</option>
          <option value="Finance">Finance</option>
          <option value="Life Insurance">Life Insurance</option>
          <option value="Hydro Power">Hydro Power</option>
          {/*<option value="Tradings">Tradings</option>*/}
          <option value="Microfinance">Microfinance</option>
          <option value="Development Banks">Development Banks</option>
          <option value="Manufacturing And Processing">
            Manufacturing And Processing
          </option>
          <option value="Commercial Banks">Commercial Banks</option>
          <option value="Others">Others</option>
          <option value="Investment">Investment</option>
          <option value="Hotels and Tourism">Hotels and Tourism</option>
        </select>
            <WatchList
              loader={this.state.watchListLoader}
              setLoaderState={this.setLoaderState}
              token={this.props.token}
              aboveFifty={this.props.aboveFifty}
              aboveThirty={this.props.aboveThirty}
              // summary={this.props.summary}
              processed={this.state.processed}
              analysisClick={this.analysisClick}
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <TopVolumeStockHolding
              loader={this.state.topVolumeLoader}
              setLoaderState={this.setLoaderState}
              headers={[
                'Code',
                'Broker',
                'Symbol',
                'Sector',
                'Holding',
                'Holding (%)',
                'Buy',
                'Sell',
              ]}
              token={this.props.token}
              topvolumes={this.props.topvolumes}
              stockHoldingCounter={this.state.stockHoldingCounter}
              analysisClick={this.analysisClick}
              brokerClick={this.brokerClick}
            />
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.authenticated,
    rBuyers: state.realtime.buyers,
    rSellers: state.realtime.sellers,
    qBuyers: state.realtime.qtyBuyers,
    qSellers: state.realtime.qtySellers,
    holdings: state.stockholding.holdings,
    topvolumes: state.stockholding.topvolumes,
    sectorwises: state.stockholding.sectorwises,
    aboveFifty: state.watchlist.aboveFifty,
    aboveThirty: state.watchlist.aboveThirty,
    // summary: state.watchlist.summary,
  };
}

export default connect(mapStateToProps, {
  viewWatchList,
  viewStockHolding,
  viewRealTimeByAmount,
  viewRealTimeByQuantity,
  viewTopVolumeStockHolding,
  viewTopSectorwiseStockHolding,
})(withRouter(DashboardPage));
