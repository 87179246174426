import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import HRNumbers from 'human-readable-numbers';
import { viewNDayAveragePrice } from 'redux/History/history.actions';
import Loader from '../Loader';
const createTr = (
  index,
  days,
  closingPriceAverage,
  totalTradedAmount,
  totalTradedShares,
  totalTrades,
  weightedAverage,
  closePrice,
  capitalGain,
) => {
  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{days}</td>
      <td>{closingPriceAverage}</td>
      <td className="align-left ">
        {HRNumbers.toHumanString(totalTradedAmount)}
      </td>
      <td className="align-left ">
        {HRNumbers.toHumanString(totalTradedShares)}
      </td>
      <td className="align-left ">{HRNumbers.toHumanString(totalTrades)}</td>
      <td className="align-left ">{weightedAverage}</td>

      <td className="align-left ">{closePrice}</td>
      <td className="align-left ">{Number(capitalGain).toFixed(0) + '%'}</td>
    </tr>
  );
};
const NAveragePrice = ({
  loader,
  headers,
  symbol,
  token,
  setLoaderState,
  loaderStr,
  navgPrices,
  viewNDayAveragePrice,
}) => {
  useEffect(() => {
    const callApi = async () => {
      console.log('here at calling api 2');
      setLoaderState(loaderStr, true);
      await viewNDayAveragePrice({
        token,
        symbol,
      });
      setLoaderState(loaderStr, false);
      console.log('here at calling api 2');
    };
    callApi();
  }, [symbol]);
  return (
    <Row>
      <Col lg={12} md={12} sm={12} xs={12}>
        <Table responsive hover className="p-2">
          <thead>
            <tr className="text-capitalize align-left ">
              {headers.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          {loader ? (
            <Loader />
          ) : (
            <tbody>
              {navgPrices && navgPrices.length
                ? navgPrices.map(
                    (
                      {
                        days,
                        closingPriceAverage,
                        totalTradedAmount,
                        totalTradedShares,
                        totalTrades,
                        weightedAverage,
                        closePrice,
                        closingDate,
                        capitalGain,
                      },
                      index,
                    ) =>
                      createTr(
                        index,
                        days,
                        closingPriceAverage,
                        totalTradedAmount,
                        totalTradedShares,
                        totalTrades,
                        weightedAverage,
                        closePrice,
                        capitalGain,
                      ),
                  )
                : null}
            </tbody>
          )}
        </Table>
      </Col>
    </Row>
  );
};

export default connect(null, {
  viewNDayAveragePrice,
})(withRouter(NAveragePrice));
