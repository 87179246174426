
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { AuthenticationForm } from 'components';

class AuthenticationPage extends React.Component {
	render() {
		return (
			<Row
				style={{
					height: '100vh',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Col md={6} lg={4}>
					<Card body>
						<AuthenticationForm />
					</Card>
				</Col>
			</Row>
		);
	}
}

export default AuthenticationPage;
