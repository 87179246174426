import React, { Component } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
// Style imports
import './styles/reduction.scss';
import 'react-sortable-tree/style.css';
import { signout } from 'redux/Authentication/authentication.actions';

import {
  AuthenticationPage,
  DashboardPage,
  MyPortfolioAnalysisPage,
  ScriptAnalysisPage,
  SectorNamePage,
  TopBrokerHoldingByDurationPage,
} from 'pages';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import { PrivateRoute } from 'utils/PrivateRoute';
import ScrollToTop from './hocs/ScrollToTop';
import BrokerByCodePage from './pages/BrokerByCodePage';
import TechnicalAnalysisPage from 'pages/TechnicalAnalysisPage';
import StrategySummaryPage from 'pages/StrategySummaryPage';
import StrategyTrackerPage from "./pages/StrategyTrackerPage";

class App extends Component {
  jwtCheck = () => {
    try {
      const { token, signout } = this.props;
      console.log(this.props);

      console.log('token decoded', jwt_decode(token));
      console.log('exp date', jwt_decode(token).exp);
      console.log('now date', Date.now() / 1000);

      if (jwt_decode(token).exp < Date.now() / 1000) {
        signout(() => {
          this.props.history.push({
            pathname: `/login`,
            state: {
              role: '',
              token: '',
            },
          });
        });
      }
    } catch (e) {
      console.log({ e });
    }
  };

  render() {
    this.jwtCheck();
    return (
      <BrowserRouter>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => <AuthenticationPage {...props} />}
          />
          <MainLayout>
            <ScrollToTop />

            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={DashboardPage}
                roleToBeChecked="ROLE_ADMIN"
              />
              <PrivateRoute
                exact
                path="/portfolio"
                component={MyPortfolioAnalysisPage}
                roleToBeChecked="ROLE_ADMIN"
              />
              <PrivateRoute
                exact
                path="/topstockholdingbyduration"
                component={TopBrokerHoldingByDurationPage}
                roleToBeChecked="ROLE_ADMIN"
              />
              <PrivateRoute
                exact
                path="/technical/:symbol"
                component={TechnicalAnalysisPage}
                roleToBeChecked="ROLE_ADMIN"
              />{' '}
              <PrivateRoute
                exact
                path="/analysis/:symbol"
                component={ScriptAnalysisPage}
                roleToBeChecked="ROLE_ADMIN"
              />
              <PrivateRoute
                exact
                path="/broker/:brokerCode"
                component={BrokerByCodePage}
                roleToBeChecked="ROLE_ADMIN"
              />
              <PrivateRoute
                exact
                path="/sector/:sectorName"
                component={SectorNamePage}
                roleToBeChecked="ROLE_ADMIN"
              />
              <PrivateRoute
                exact
                path="/strategy"
                component={StrategySummaryPage}
                roleToBeChecked="ROLE_ADMIN"
              />
              <PrivateRoute
                  exact
                  path="/strategy-tracker"
                  component={StrategyTrackerPage}
                  roleToBeChecked="ROLE_ADMIN"
              />
            </Switch>
          </MainLayout>

          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.auth.role,
    token: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, { signout })(App);
