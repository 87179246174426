import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TopHoldingBroker } from 'components';
import { viewRealTimeByQuantity } from 'redux/Realtime/realtime.actions';
import { useQuery } from 'react-query';
import { getTopHoldingBrokerByQuantity } from 'api/realtime';
import { Input, Row } from 'reactstrap';
import moment from 'moment';
const RealTimeTopHoldingBrokerByQuantity = ({
  headers,
  token,
  analysisClick,
  brokerClick,
  isQuantity = true,
}) => {
  const [date,setDate] = useState("");
  let buyers, sellers;
  const { data, isError, error, isLoading, isFetched } = useQuery(
    isQuantity ? ['TopHoldingBrokerByQuantity',date] : ['TopHoldingBrokerByAmount',date],
    () => getTopHoldingBrokerByQuantity(token, isQuantity,date),
    {
      staleTime: 2000,
      keepPreviousData: true,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
  );
  if (isFetched && data) {
    if (data.data) {
      buyers = data.data.buyers;
      sellers = data.data.sellers;
    }
  }
  if (isError) {
    return <div>{error.toString()}</div>;
  }
  const dateChange = event => {
    console.log(event.target.name);
    setDate(event.target.value);
  };
  return (
    <>
      <Row>
        <Input
          type="date"
          name={'date'}
          id="dateInput"
          placeholder="date placeholder"
          min="2021-05-05"
          max={moment().format('YYYY-MM-DD')}
          onChange={dateChange}
          className="col-3 form-control-sm ml-5 mt-2"
        />

      </Row>
      <TopHoldingBroker
        loader={isLoading}
        cardTitle={`Realtime broker(s) By ${isQuantity ? 'Quantity' : 'Amounts'}`}
        headers={headers}
        buyers={buyers}
        sellers={sellers}
        analysisClick={analysisClick}
        brokerClick={brokerClick}
      />
    </>
  );
};

export default connect(null, {
  viewRealTimeByQuantity,
})(withRouter(RealTimeTopHoldingBrokerByQuantity));
