import React from 'react';
import {Table} from 'reactstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import HRNumbers from 'human-readable-numbers';
import DataTable from 'react-data-table-component';

function getDatas(days) {
    const data = [];
    if (days && days.length > 0) {
        days.map(
            (
                day,
                key
            ) => {
                data.push({
                    ...day,
                    sectorName: day.sectorName + " (" + day.supplyMessage + ")",
                    buyPl: Number(day.ltp - day.avgBuyCost.replace(/,/g, '')).toFixed(2),
                    sellPl: Number(day.avgSellCost.replace(/,/g, '') - day.ltp).toFixed(2)
                })
            });
    }
    return data;
}

function getHeaders(showSymbol, isLtp) {
    if (isLtp) {
        return [{
            name: 'Code',
            selector: 'number',
            sortable: true,
            width: '5%',
        }, {
            name: 'Name',
            selector: 'name',
            sortable: true,
            width: '7%'
        }, {
            name: 'Symbol',
            selector: 'symbol',
            sortable: true,
            width: '7%',
        }, {
            name: 'Sector Name',
            selector: 'sectorName',
            sortable: true,
            width: '18%'
        }, {
            name: 'Holding',
            selector: 'stockHoldings',
            sortable: true,
            cell: row => (
                <div>{HRNumbers.toHumanString(row.stockHoldings.replace(/,/g, ''))}</div>
            ),
            width: '7%'
        }, {
            name: 'Holding (%)',
            selector: 'holdingPercentage',
            sortable: true,
            width: '7%',
            cell: row => (
                <div>{row.holdingPercentage +" %"}</div>
            ),
        },
            {
                name: 'Buy',
                selector: 'buyQty',
                sortable: true,
                width: '7%',
                cell: row => (
                    <div>{HRNumbers.toHumanString(row.buyQty.replace(/,/g, ''))}</div>
                ),
            },
            {
                name: 'Sell',
                selector: 'sellQty',
                sortable: true,
                width: '7%',
                cell: row => (
                    <div>{HRNumbers.toHumanString(row.sellQty.replace(/,/g, ''))}</div>
                ),
            },
            {
                name: 'Avg Buy',
                selector: 'avgBuyCost',
                sortable: true,
                width: '7%',
            },
            {
                name: 'LTP',
                selector: 'ltp',
                sortable: true,
                width: '7%',
            }, {
                name: 'Buy P/L',
                selector: 'buyPl',
                sortable: true,
                width: '7%',
            }, {
                name: 'Avg Sell',
                selector: 'avgSellCost',
                sortable: true,
                width: '7%',
            }, {
                name: 'Sell P/L',
                selector: 'sellPl',
                sortable: true,
                width: '7%',
            }

        ];
    }
    if (showSymbol) {
        return [
            'Code',
            'Name',
            'Symbol',
            'Sector Name',
            'Holding',
            'Holding (%)',
            'Buy',
            'Sell',
            'Avg Buy',
            'Avg Sell',
        ];
    }
    return [
        'Code',
        'Name',
        'Holding',
        'Holding (%)',
        'Avg Buy',
        'Avg Sell',
    ];
}

const DaywiseAnalysis = ({
                             label,
                             days,
                             analysisClick,
                             brokerClick,
                             showSymbol = false,
                             headers,
                             isLtp = false,
                         }) => {
    headers = getHeaders(showSymbol, isLtp);
    if (isLtp) {
        const customStyles = {
            rows: {
                style: {
                    minHeight: '30px', // override the row height
                    width: '700px'
                },
            },
            headCells: {
                style: {
                    paddingLeft: '8px', // override the cell padding for head cells
                    paddingRight: '8px',
                    width: '2px'
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px', // override the cell padding for data cells
                    paddingRight: '8px',
                    width: '2px'
                },
            },
        };
        return (<DataTable
            columns={headers}
            data={getDatas(days)}

            title={label}

        />)
    }
    return (
        <>
            <h5>{label}</h5>
            <Table responsive hover size="sm">
                <thead>
                <tr className="text-capitalize align-left ">
                    {headers.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {days && days.length > 0 ? (
                    days.map(
                        (
                            {
                                number,
                                name,
                                stockHoldings,
                                holdingPercentage,
                                symbol,
                                sectorName,
                                buyQty,
                                sellQty,
                                ltp,
                                avgBuyCost,
                                avgSellCost,
                                supplyMessage
                            },
                            key,
                        ) => (
                            <tr
                                key={key}

                            >
                                <td onClick={() => brokerClick(number)}>{number}</td>
                                <td className="align-left " title={name}>
                                    {name.split(' ')[0]}
                                </td>
                                {showSymbol ? <>
                                    <td onClick={() => (showSymbol ? analysisClick(symbol) : '')}>{symbol}</td>
                                    <td>{sectorName + " (" + supplyMessage + ")"}</td>
                                </> : <></>}
                                <td className="align-left " title={stockHoldings}>
                                    {HRNumbers.toHumanString(stockHoldings.replace(/,/g, ''))}
                                </td>
                                <td className="align-left ">{holdingPercentage + '%'}</td>
                                {showSymbol ? (
                                    <>
                                        <td className="align-left " title={buyQty}>
                                            {HRNumbers.toHumanString(buyQty.replace(/,/g, ''))}
                                        </td>
                                        <td className="align-left " title={sellQty}>
                                            {HRNumbers.toHumanString(sellQty.replace(/,/g, ''))}
                                        </td>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <td className="align-left ">{'Rs. ' + avgBuyCost}</td>
                                {
                                    isLtp ? (<>
                                        <td className="align-left " title={ltp}>
                                            {'Rs. ' + ltp}
                                        </td>
                                        <td className="align-left " title={ltp}>
                                            {'Rs. ' + Number(ltp - avgBuyCost.replace(/,/g, '')).toFixed(2)}
                                        </td>
                                    </>) : (<></>)
                                }
                                <td className="align-left " title={'Rs. ' + avgSellCost}>
                                    {'Rs. ' + avgSellCost}
                                </td>
                                {
                                    isLtp ? (<>
                                        <td className="align-left " title={ltp}>
                                            {'Rs. ' + Number(avgSellCost.replace(/,/g, '') - ltp).toFixed(2)}
                                        </td>
                                    </>) : (<></>)
                                }
                            </tr>
                        ),
                    )
                ) : (
                    <tr>
                        <td colSpan="2" className="">
                            No Record(s)
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </>
    );
};

export default connect(null)(withRouter(DaywiseAnalysis));
