import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Card, CardBody, CardHeader } from 'reactstrap';
import { viewTopVolumeStockHolding } from 'redux/StockHolding/stockholding.actions';

import { Loader, StockHoldingTable } from 'components';

const TopVolumeStockHolding = ({
  loader,
  headers,
  setLoaderState,
  stockHoldingCounter,
  token,
  topvolumes,
  viewTopVolumeStockHolding,
  analysisClick,
  brokerClick,
}) => {
  useEffect(() => {
    const callApi = async () => {
      setLoaderState('topVolumeLoader', true);
      await viewTopVolumeStockHolding({ token });
      setLoaderState('topVolumeLoader', false);
    };
    callApi();
  }, [stockHoldingCounter]);
  return (
    <Card>
      <CardHeader>Volume Wise Stock Holding</CardHeader>
      <CardBody>
        {loader ? (
          <Loader />
        ) : (
          <>
            {topvolumes && Object.keys(topvolumes).length > 0 ? (
              Object.keys(topvolumes).map(key => (
                <StockHoldingTable
                  holdings={topvolumes[key]}
                  tableTitle={key}
                  key={key}
                  headers={headers}
                  analysisClick={analysisClick}
                  brokerClick={brokerClick}
                />
              ))
            ) : (
              <div className="col-12 no-record">No Record(s)</div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};
export default connect(null, {
  viewTopVolumeStockHolding,
})(withRouter(TopVolumeStockHolding));
