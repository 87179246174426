import { stockHoldingTypes } from './stockholding.types';
const initialState = {
	holdings: [],
	topvolumes: {},
	sectorwises:{},
	sectorNames :[],
	errorMessage: ''
};
export default function(state = initialState, action) {
	switch (action.type) {
		case stockHoldingTypes.VIEW_STOCK_HOLDING:
			return {
				...state,
				holdings: action.payload.content
			};
		case stockHoldingTypes.VIEW_STOCK_HOLDING_BY_BROKER_CODE:
			return {
				...state,
				holdings: action.payload.content
			};
		case stockHoldingTypes.VIEW_TOP_VOLUME_STOCK_HOLDING_ERROR:
		case stockHoldingTypes.VIEW_TOP_SECTORWISE_STOCK_HOLDING_ERROR:
		case stockHoldingTypes.VIEW_STOCK_HOLDING_ERROR:
			return {
				...state,
				errorMessage: action.payload
			};
		case stockHoldingTypes.VIEW_TOP_VOLUME_STOCK_HOLDING:
			return {
				...state,
				topvolumes: action.payload
			};
		case stockHoldingTypes.VIEW_TOP_SECTORWISE_STOCK_HOLDING:
			return {
				...state,
				sectorwises: action.payload
			};
		case stockHoldingTypes.VIEW_TOP_SECTORNAME_STOCK_HOLDING:
			return {
				...state,
				sectorNames: action.payload.content
			};
		default:
			return state;
	}
}
