import React from 'react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {callScriptAnalysis} from 'redux/Script/script.actions';
import {
    viewHistoryByQuantity,
    viewNDayAveragePrice,
} from 'redux/History/history.actions';
import {
    Loader,
    ScriptPortfolio,
    Page,
    IconWidget,
    TodayScriptTechnicalIndicator,
    PriceDifference,
    NAveragePrice,
} from '../components';
import {registration, unsubscribe} from '../hocs/websocket-listener';
import {
    Row,
    Col,
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Badge,
} from 'reactstrap';
import {getColor} from 'utils/colors';
import _ from 'lodash';
import {
    MdBubbleChart,
    MdImportExport,
    MdInsertChart,
    MdLightbulbOutline,
    MdReceipt,
    MdShop,
    MdShowChart,
    MdThumbUp,
    MdBusiness,
    MdCheckCircle,
    MdDateRange,
    MdCompareArrows,
    MdPermIdentity,
    MdRotateLeft,
} from 'react-icons/md';
import HRNumbers from 'human-readable-numbers';
import moment from 'moment';

class ScriptAnalysisPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            realTimePortfolioAnalysisLoader: false,
            overallTopHoldingBrokerLoader: false,
            technicalIndicatorTodayLoader: false,
            naveragePriceLoader: false,
            processed: 0,
            stockHoldingCounter: 0,
            symbol: '',
            qtydateQuery: '',
        };
    }

    async componentDidMount() {
        // registration([
        //   {
        //     route: '/queue/mrmarketui.queue.floorsheet',
        //     callback: this.refreshFloorsheetChanged,
        //   },
        //   {
        //     route: '/queue/mrmarketui.queue.shp',
        //     callback: this.refreshStockHoldingChanged,
        //   },
        // ]);
        await this.callScriptAnalysisApi();
    }

    componentWillUnmount() {
        // unsubscribe([
        //   {
        //     route: '/queue/mrmarketui.queue.floorsheet',
        //     callback: this.refreshFloorsheetChanged,
        //   },
        //   {
        //     route: '/queue/mrmarketui.queue.shp',
        //     callback: this.refreshStockHoldingChanged,
        //   },
        // ]);
    }

    setLoaderState = (loaderName, loaderState) => {
        this.setState({[loaderName]: loaderState});
    };
    callScriptAnalysisApi = async () => {
        this.setState({realTimePortfolioAnalysisLoader: true});
        await this.props.callScriptAnalysis({
            token: this.props.token,
            symbol: this.props.match.params.symbol,
        });

        this.setState({
            realTimePortfolioAnalysisLoader: false,
            symbol: this.props.match.params.symbol,
        });
    };

    refreshFloorsheetChanged = async message => {
        this.setState({processed: parseInt(message.body)});
        await this.callScriptAnalysisApi();
    };
    refreshStockHoldingChanged = async message => {
        const counter =
            message.body === 'completed'
                ? this.state.stockHoldingCounter + 1
                : this.state.stockHoldingCounter;
        console.log(counter);
        this.setState({
            stockHoldingCounter: counter,
        });
        await this.callScriptAnalysisApi();
    };

    brokerClick = brokerCode => {
        console.log(brokerCode);
        this.props.history.push('/broker/' + brokerCode);
    };
    dateChange = event => {
        console.log(event.target.name);
        this.setState({
            qtydateQuery: event.target.value,
        });
    };

    render() {
        const primaryColor = getColor('primary');
        const secondaryColor = getColor('secondary');
        return (
            <Page
                className="ScriptAnalysisPage"
                title="Script Analysis"
                breadcrumbs={[
                    {name: 'Script Analysis', active: false},
                    {
                        name:
                        this.props.match.params.symbol,
                        active: true,
                    },
                ]}
            >
                {_.isEmpty(this.props.portfolioAnalysis) ? (
                    <Loader/>
                ) : (
                    <>
                        <Row>
                            {this.props.portfolioAnalysis.myPortfolioResponse ? (
                                <Col lg="4" md="12" sm="12" xs="12">
                                    <IconWidget
                                        bgColor={
                                            this.props.portfolioAnalysis.myPortfolioResponse
                                                .profitLoss > 0
                                                ? 'primary'
                                                : 'danger'
                                        }
                                        icon={
                                            this.props.portfolioAnalysis.myPortfolioResponse
                                                .profitLoss > 0
                                                ? MdThumbUp
                                                : MdLightbulbOutline
                                        }
                                        title={`P/L : ${Number(
                                            this.props.portfolioAnalysis.myPortfolioResponse
                                                .profitLoss,
                                        ).toFixed(2)} % (Rs. ${Number(
                                            this.props.portfolioAnalysis.myPortfolioResponse
                                                .netProfitLossRs,
                                        ).toFixed(2)}  )`}
                                        subtitle={`
                    QTY: ${HRNumbers.toHumanString(
                                            this.props.portfolioAnalysis.myPortfolioResponse.quantity,
                                        )}
                CP: ${
                                            this.props.portfolioAnalysis.myPortfolioResponse
                                                .costPricePerShareRs
                                        }
                `}
                                        color={
                                            this.props.portfolioAnalysis.myPortfolioResponse
                                                .profitLoss > 0
                                                ? 'primary'
                                                : 'danger'
                                        }
                                    />
                                </Col>
                            ) : (
                                <></>
                            )}
                            <PriceDifference
                                dailyTrade={this.props.dailyTrade}
                                priceDifference={this.props.priceDifference}
                            />
                        </Row>

                        <Row className="m-2">
                            <Col lg="4" md="12" sm="12" xs="12">
                                <Card>
                                    <CardHeader>Daily Trade Detail</CardHeader>
                                    <ListGroup flush>
                                        {this.props.portfolioAnalysis.security &&
                                        this.props.portfolioAnalysis.security !== null ? (
                                            <ListGroupItem>
                                                <MdPermIdentity size={25} color={primaryColor}/>

                                                {this.props.portfolioAnalysis.security.securityName}
                                            </ListGroupItem>
                                        ) : null}
                                        <ListGroupItem>
                                            <MdInsertChart size={25} color={primaryColor}/> LTP{' '}
                                            <Badge color="secondary" className="float-right">
                                                {`Rs . ${
                                                    this.props.dailyTrade.lastTradedPrice
                                                } (Rs .${Number(
                                                    this.props.dailyTrade.lastTradedPrice -
                                                    this.props.dailyTrade.previousClose,
                                                ).toFixed(1)})`}
                                            </Badge>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <MdReceipt size={25} color={primaryColor}/> Total Volume{' '}
                                            <Badge color="secondary" className="float-right">
                                                {HRNumbers.toHumanString(
                                                    this.props.dailyTrade.totalTradeQuantity,
                                                )}
                                            </Badge>
                                        </ListGroupItem>
                                        {this.props.portfolioAnalysis.security &&
                                        this.props.portfolioAnalysis.security !== null &&
                                        this.props.portfolioAnalysis.security.stockListedShares ? (
                                            <ListGroupItem>
                                                <MdRotateLeft size={25} color={primaryColor}/>% Volume{' '}
                                                <Badge color="secondary" className="float-right">
                                                    {Number(
                                                        (this.props.dailyTrade.totalTradeQuantity /
                                                            this.props.portfolioAnalysis.security
                                                                .stockListedShares) *
                                                        100,
                                                    ).toFixed(1)}
                                                    {' %'}
                                                </Badge>
                                            </ListGroupItem>
                                        ) : null}
                                        <ListGroupItem>
                                            <MdShop size={25} color={primaryColor}/> Total Trade{' '}
                                            <Badge color="secondary" className="float-right">
                                                {this.props.dailyTrade.totalTrades}
                                            </Badge>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <MdBubbleChart size={25} color={primaryColor}/>{' '}
                                            Open-Close{' '}
                                            <Badge color="secondary" className="float-right">
                                                {'Rs . ' +
                                                this.props.dailyTrade.openPrice +
                                                ' - ' +
                                                'Rs. ' +
                                                this.props.dailyTrade.closePrice}
                                            </Badge>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <MdImportExport size={25} color={primaryColor}/> High -
                                            Low{' '}
                                            <Badge color="secondary" className="float-right">
                                                {'Rs . ' +
                                                this.props.dailyTrade.highPrice +
                                                ' - ' +
                                                'Rs. ' +
                                                this.props.dailyTrade.lowPrice}
                                            </Badge>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <MdShowChart size={25} color={primaryColor}/> 52WH - 52WL{' '}
                                            <Badge color="secondary" className="float-right">
                                                {'Rs . ' +
                                                this.props.dailyTrade.fiftyTwoWeekHigh +
                                                ' - ' +
                                                'Rs. ' +
                                                this.props.dailyTrade.fiftyTwoWeekLow}
                                            </Badge>
                                        </ListGroupItem>
                                        {this.props.portfolioAnalysis.supplyMessage &&
                                        this.props.portfolioAnalysis.supplyMessage !== '' ? (
                                            <ListGroupItem>
                                                <MdCompareArrows size={25} color={primaryColor}/>
                                                {' Supply - '}
                                                {this.props.portfolioAnalysis.supplyMessage}
                                            </ListGroupItem>
                                        ) : null}

                                        {this.props.portfolioAnalysis.security &&
                                        this.props.portfolioAnalysis.security !== null ? (
                                            <ListGroupItem>
                                                <MdCheckCircle size={25} color={primaryColor}/>
                                                {' Listed Share (P/P) - '}
                                                {HRNumbers.toHumanString(
                                                    this.props.portfolioAnalysis.security
                                                        .stockListedShares,
                                                ) +
                                                ' ( ' +
                                                this.props.portfolioAnalysis.security
                                                    .publicPercentage +
                                                '% / ' +
                                                this.props.portfolioAnalysis.security
                                                    .promoterPercentage +
                                                '%)'}
                                            </ListGroupItem>
                                        ) : null}
                                        {this.props.portfolioAnalysis.security &&
                                        this.props.portfolioAnalysis.security !== null ? (
                                            <ListGroupItem>
                                                <MdCheckCircle size={25} color={primaryColor}/>
                                                {' Public share - '}
                                                {HRNumbers.toHumanString(
                                                    this.props.portfolioAnalysis.security
                                                        .publicShares,
                                                )}
                                                {' Promoter Share - ' + HRNumbers.toHumanString(this.props.portfolioAnalysis.security
                                                    .promoterShares)}
                                            </ListGroupItem>
                                        ) : null}
                                        {this.props.portfolioAnalysis.security &&
                                        this.props.portfolioAnalysis.security !== null ? (
                                            <ListGroupItem>
                                                <MdDateRange size={25} color={primaryColor}/>
                                                {' Listed Date - '}
                                                {this.props.portfolioAnalysis.security.listingDate + "\n listed  " + moment(

                                                ).diff(
                                                    moment(
                                                        this.props.portfolioAnalysis.security.listingDate
                                                    ),
                                                    'years',
                                                ) + " years"}
                                            </ListGroupItem>
                                        ) : null}
                                        {
                                            moment(

                                            ).diff(
                                                moment(
                                                    this.props.portfolioAnalysis.security.listingDate
                                                ),
                                                'years',
                                            ) < 3 ? <ListGroupItem>
                                              {' In Case of lockin period - WIP'}
                                            </ListGroupItem> : null
                                        }
                                        <ListGroupItem>
                                            <MdBusiness size={25} color={primaryColor}/>{' '}
                                            {this.props.portfolioAnalysis.sectorName}
                                        </ListGroupItem>
                                    </ListGroup>
                                </Card>
                            </Col>
                            <Col lg="8" md="12" sm="12" xs="12">
                                <Card>
                                    <CardHeader>N Day's Trading Average Price</CardHeader>
                                    <NAveragePrice
                                        index={12}
                                        loader={this.state.naveragePriceLoader}
                                        setLoaderState={this.setLoaderState}
                                        token={this.props.token}
                                        loaderStr="naveragePriceLoader"
                                        headers={[
                                            'SN.',
                                            'Day(s)',
                                            'Closing Price Avg',
                                            'Total Traded A',
                                            'Total Traded S',
                                            'Total Trades',
                                            'Weighted Avg',
                                            'Closing Price',
                                            'Capital Gain',
                                        ]}
                                        symbol={this.props.match.params.symbol}
                                        navgPrices={this.props.navgPrices}
                                    />
                                </Card>
                            </Col>

                            <ScriptPortfolio
                                portfolio={this.props.portfolioAnalysis}
                                index={0}
                                loader={this.state.realTimePortfolioAnalysisLoader}
                                overallLoader={this.state.overallTopHoldingBrokerLoader}
                                setLoaderState={this.setLoaderState}
                                previousBuyers={this.props.previousBuyers}
                                previousSellers={this.props.previousSellers}
                                token={this.props.token}
                                brokerClick={this.brokerClick}
                                dateChange={this.dateChange}
                                dateQuery={this.state.qtydateQuery}
                            />
                        </Row>
                    </>
                )}
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.authenticated,
        portfolioAnalysis: state.script.portfolioAnalysis,
        dailyTrade: state.script.dailyTrade,
        priceDifference: state.script.priceDifference,
        previousBuyers: state.overallAnalysis.qtyBuyers,
        previousSellers: state.overallAnalysis.qtySellers,
        navgPrices: state.overallAnalysis.navgPrices,
    };
}

export default connect(mapStateToProps, {
    callScriptAnalysis,
    viewHistoryByQuantity,
    viewNDayAveragePrice,
})(withRouter(ScriptAnalysisPage));
