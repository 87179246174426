import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconWidget, Loader, ScriptPortfolio } from 'components';
import { viewRealTimePortfolioAnalysis } from 'redux/Realtime/realtime.actions';
import { MdLightbulbOutline, MdThumbUp } from 'react-icons/md';
import HRNumbers from 'human-readable-numbers';

const MyPortfolioAnalysis = ({
  loader,
  processed,
  portfolioAnalysis,
  setLoaderState,
  token,
  viewRealTimePortfolioAnalysis,
  addPortfolioAnalysis,
  analysisClick,
}) => {
  useEffect(() => {
    const callApi = async () => {
      setLoaderState('realTimePortfolioAnalysisLoader', true);
      await viewRealTimePortfolioAnalysis({ token });
      addPortfolioAnalysis(true);
      setLoaderState('realTimePortfolioAnalysisLoader', false);
    };
    callApi();
  }, [processed]);

  return (
    <Card>
      <CardHeader>My Portfolio Anlysis</CardHeader>
      <CardBody>
        {loader ? (
          <Loader />
        ) : (
          <Row>
            {portfolioAnalysis && portfolioAnalysis.length ? (
              portfolioAnalysis.map((portfolio, index) => (
                <>
                  {portfolio.myPortfolioResponse ? (
                    <IconWidget
                      bgColor={
                        portfolio.myPortfolioResponse.profitLoss > 0
                          ? 'primary'
                          : 'danger'
                      }
                      icon={
                        portfolio.myPortfolioResponse.profitLoss > 0
                          ? MdThumbUp
                          : MdLightbulbOutline
                      }
                      title={`${
                        portfolio.myPortfolioResponse.companySymbol
                      } P/L : ${Number(
                        portfolio.myPortfolioResponse.profitLoss,
                      ).toFixed(2)} % (Rs. ${Number(
                        portfolio.myPortfolioResponse.netProfitLossRs,
                      ).toFixed(2)}  )`}
                      subtitle={`
                                                  QTY: ${HRNumbers.toHumanString(
                                                    portfolio
                                                      .myPortfolioResponse
                                                      .quantity,
                                                  )}
                                                  CP: ${
                                                    portfolio
                                                      .myPortfolioResponse
                                                      .costPricePerShareRs
                                                  }
                                                  `}
                      color={
                        portfolio.myPortfolioResponse.profitLoss > 0
                          ? 'primary'
                          : 'danger'
                      }
                    />
                  ) : (
                    <></>
                  )}

                  <ScriptPortfolio
                    portfolio={portfolio}
                    index={index}
                    loader={loader}
                    analysisClick={analysisClick}
                  />
                </>
              ))
            ) : (
              <div>No Data Found</div>
            )}
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default connect(null, {
  viewRealTimePortfolioAnalysis,
})(withRouter(MyPortfolioAnalysis));
