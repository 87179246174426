import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col } from 'reactstrap';
import {
  ScriptAnalysis,
  TopHoldingBroker,
  StockHoldingTable,
  OverallTopHoldingBroker,
} from '..';

const ScriptPortfolio = ({
  loader,
  portfolio,
  index,
  analysisClick,
  brokerClick,
  setLoaderState,
  previousBuyers,
  previousSellers,
  overallLoader,
  token,

  dateChange,
  dateQuery,
}) => {
  console.log(portfolio,"portfolio");
  const {
    name,
    myPortfolioResponse,
    pivotMovingAvgResponse,
    lastOneDays,
    lastTwoDays,
    lastThreeDays,
    lastSevenDays,
    lastFourteenDays,
    lastOneMonth,
    lastThreeMonth,
    lastSixMonth,
    lastOneYear,
    todays,
    stockHoldings,
  } = portfolio;
  return (
    <>
      <Col sm={12} xs={12}>
        <TopHoldingBroker
          loader={loader}
          cardTitle="Today broker(s)"
          headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount']}
          buyers={
            todays && todays.buyers && todays.buyers.length > 0
              ? todays.buyers
              : []
          }
          sellers={
            todays && todays.sellers && todays.sellers.length > 0
              ? todays.sellers
              : []
          }
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
      </Col>

      { previousBuyers && previousSellers ? (
        <Col sm={12} xs={12}>
          <OverallTopHoldingBroker
            loader={overallLoader}
            headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount']}
            buyers={previousBuyers}
            sellers={previousSellers}
            analysisClick={analysisClick}
            symbol={portfolio.symbol}
            token={token}
            setLoaderState={setLoaderState}
            brokerClick={brokerClick}
            dateQuery={dateQuery}
            dateChange={dateChange}
          />
        </Col>
      ) : (
        <></>
      )}
      <Col lg={6} md={12} sm={12} xs={12}>
        <StockHoldingTable
          holdings={stockHoldings}
          headers={[
              'SN.',
            'Code',
            'Broker',
            'Symbol',
            'Sector',
            'Holding',
            'Holding (%)',
            'Buy',
            'Sell',
              'Ltp.'
          ]}
          tableTitle="Top Stock Holding (Today)"
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
      </Col>
      <ScriptAnalysis
        key={index}
        name={name}
        myPortfolioResponse={myPortfolioResponse}
        pivotMovingAvgResponse={pivotMovingAvgResponse}
        lastOneDays={lastOneDays}
        lastTwoDays={lastTwoDays}
        lastThreeDays={lastThreeDays}
        lastSevenDays={lastSevenDays}
        lastFourteenDays={lastFourteenDays}
        lastOneMonth={lastOneMonth}
        lastThreeMonth={lastThreeMonth}
        lastSixMonth={lastSixMonth}
        lastOneYear={lastOneYear}
        analysisClick={analysisClick}
        brokerClick={brokerClick}
      />
    </>
  );
};
export default connect(null)(withRouter(ScriptPortfolio));
