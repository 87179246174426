import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import logo200Image from 'assets/img/logo/logo_200.png';

import { signin, setSubmitLoader } from 'redux/Authentication/authentication.actions';
class AuthenticationForm extends React.Component {
	constructor() {
		super();
		this.state = {
			username: '',
			password: '',
			submitLoader: false
		};
	}
	
	componentDidUpdate(previousProps) {
		if (this.props.submitLoader !== previousProps.errorMessage) {
			this.setState({ submitLoader: false });
		}
	}
	handleSubmit = (event) => {
		event.preventDefault();

		this.setState({ submitLoader: true });

		const formProps = {
			username: this.state.username,
			password: this.state.password
		};
		this.props.signin(formProps, () => {
			this.setState({ submitLoader: false });
			this.props.history.push('/');
		});
	};
	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	render() {
		let loginButton = this.state.submitLoader ? (
			<Button className="btn-login">
				<i className="fa fa-spinner fa-spin" aria-hidden="true" />
			</Button>
		) : (
			<input type="submit" value="login" className="btn-login" />
		);
		return (
			<Form onSubmit={this.handleSubmit}>
				<div className="text-center pb-4">
					<img
						src={logo200Image}
						className="rounded"
						style={{ width: 60, height: 60, cursor: 'pointer' }}
						alt="logo"
					/>
				</div>
				<FormGroup>
					<Label for="username">Username</Label>
					<Input
						required
						type="text"
						name="username"
						placeholder="Username"
						id="username"
						onChange={this.onInputChange}
					/>
					<FormFeedback>Username is required</FormFeedback>
				</FormGroup>
				<FormGroup>
					<Label for="password">Password</Label>
					<Input
						required
						type="password"
						name="password"
						id="password"
						placeholder="Password"
						onChange={this.onInputChange}
					/>
					<FormFeedback>Password is required</FormFeedback>
				</FormGroup>
				<div className="text-danger">{this.props.errorMessage}</div>
				<hr />
				{loginButton}
			</Form>
		);
	}
}

function mapStateToProps(state) {
	return {
		errorMessage: state.auth.errorMessage
	};
}

export default connect(mapStateToProps, { setSubmitLoader, signin })(withRouter(AuthenticationForm));
