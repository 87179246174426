import React from 'react';
import {Row, Card, CardBody, CardHeader, Col, Table} from 'reactstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Loader} from 'components';
import HRNNumber from 'human-readable-numbers';

const returnTableData = (brokerName, brokerCode, symbol, count, amount, ltp, index, analysisClick, brokerClick) => {
    return (
        <tr key={index}>
            <td onClick={() => brokerClick(brokerCode)}>{brokerCode}</td>
            <td className="align-left " title={brokerName} onClick={() => brokerClick(brokerCode)}>
                {brokerName ? brokerName.split(' ')[0] : ''}
            </td>
            <td className="align-left " onClick={() => analysisClick(symbol)}>
                {symbol}
            </td>
            <td className="align-left " title={count}>
                {HRNNumber.toHumanString(count)}
            </td>
            <td className="align-left " title={'Rs. ' + amount}>
                {'Rs. ' + HRNNumber.toHumanString(amount)}
            </td>
            {
                ltp > 0 ? <td className="align-left " title={'Rs. ' + ltp}>
                    {'Rs. ' + ltp}
                </td> : null
            }
        </tr>
    );
};
const TopHoldingBroker = ({loader, cardTitle, headers, buyers, sellers, analysisClick, brokerClick}) => {
    return (
        <Card className="mt-1">
            <CardHeader>{cardTitle}</CardHeader>
            <CardBody>
                {loader ? (
                    <Loader/>
                ) : (
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <h5>Top Buyer(s)</h5>
                            <Table responsive hover size="sm">
                                <thead>
                                <tr className="text-capitalize align-left ">
                                    {headers.map((item, index) => <th key={index}>{item}</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {buyers && buyers.length ? (
                                    buyers.map(({brokerName, brokerCode, symbol, count, amount, ltp}, index) =>
                                        returnTableData(
                                            brokerName,
                                            brokerCode,
                                            symbol,
                                            count,
                                            amount,
                                            ltp,
                                            index,
                                            analysisClick,
                                            brokerClick
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="">
                                            No Record(s)
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <h5>Top Seller(s)</h5>
                            <Table responsive hover size="sm">
                                <thead>
                                <tr className="text-capitalize align-left ">
                                    {headers.map((item, index) => <th key={index}>{item}</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {sellers && sellers.length ? (
                                    sellers.map(({brokerName, brokerCode, symbol, count, amount, ltp}, index) =>
                                        returnTableData(
                                            brokerName,
                                            brokerCode,
                                            symbol,
                                            count,
                                            amount,
                                            ltp,
                                            index,
                                            analysisClick,
                                            brokerClick
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="2" className="">
                                            No Record(s)
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )}
            </CardBody>
        </Card>
    );
};

export default connect(null)(withRouter(TopHoldingBroker));
