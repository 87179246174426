import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TopHoldingBroker } from 'components';
import { viewHistoryByQuantity } from 'redux/History/history.actions';
import Input from 'reactstrap/lib/Input';
import moment from 'moment';

const OverallTopHoldingBroker = ({
	loader,
	headers,
	buyers,
	sellers,
	setLoaderState,
	token,
	viewHistoryByQuantity,
	symbol,
	analysisClick,
	brokerClick,
	dateChange,
	dateQuery
}) => {
	useEffect(
		() => {
			const callApi = async () => {
				console.log('here at calling api 2');
				setLoaderState('overallTopHoldingBrokerLoader', true);
				console.log('symbol', symbol);
				await viewHistoryByQuantity({ token, symbol, dateQuery });
				setLoaderState('overallTopHoldingBrokerLoader', false);
				console.log('here at calling api 2');
			};
			callApi();
		},
		[ symbol, dateQuery ]
	);

	return (
		<div>
			<Input
				type="date"
				name="date"
				id="dateInput"
				placeholder="date placeholder"
				min="2021-05-05"
				max={moment().format('YYYY-MM-DD')}
				onChange={dateChange}
				className="col-3 form-control-sm"
			/>
			<TopHoldingBroker
				loader={loader}
				cardTitle={'Upto Yesterday broker(s) By Quantity'}
				headers={headers}
				buyers={buyers}
				sellers={sellers}
				analysisClick={analysisClick}
				brokerClick={brokerClick}
			/>
		</div>
	);
};

export default connect(null, {
	viewHistoryByQuantity
})(withRouter(OverallTopHoldingBroker));
