export const realTimeActionTypes = {
    VIEW_REALTIME_THB: 'VIEW_REALTIME_THB',
    VIEW_REALTIME_THB_ERROR: 'VIEW_REALTIME_THB_ERROR',

    VIEW_REALTIME_BY_QUANTITY: 'VIEW_REALTIME_BY_QUANTITY',
    VIEW_REALTIME_BY_QUANTITY_ERROR: 'VIEW_REALTIME_BY_QUANTITY_ERROR',

    VIEW_REALTIME_PA: 'VIEW_REALTIME_PA',
    VIEW_REALTIME_PA_ERROR: 'VIEW_REALTIME_PA_ERROR',

    VIEW_REALTIME_BROKERWISE: 'VIEW_REALTIME_BROKERWISE',
    VIEW_REALTIME_BROKERWISE_ERROR: 'VIEW_REALTIME_BROKERWISE_ERROR',

    VIEW_REALTIME_BROKERWISE_BY_AMOUNT: 'VIEW_REALTIME_BROKERWISE_BY_AMOUNT',
    VIEW_REALTIME_BROKERWISE_BY_AMOUNT_ERROR: 'VIEW_REALTIME_BROKERWISE_BY_AMOUNT_ERROR',
}