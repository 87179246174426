import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {Table} from 'reactstrap';


const WatchListTable = ({headers, pricedifferences, tableTitle, analysisClick}) => {
    return (
        <div>
            <h5>{tableTitle}</h5>
            <Table responsive hover size="sm">
                <thead>
                <tr className="text-capitalize align-left">
                    {headers.map((item, index) => <th key={index}>{item}</th>)}
                </tr>
                </thead>
                <tbody>
                {pricedifferences && pricedifferences.length ? (
                    pricedifferences.map(
                        (
                            {
                                symbol,
                                dropPercentage,
                                ltp,
                                nearestLowPercentage,
                                fiftyWeekHigh,
                                fiftyWeekLow
                            },
                            index
                        ) => (
                            <tr key={index} onClick={() => analysisClick(symbol)}>
                                <td className="align-left " title={symbol}>{symbol}</td>
                                <td className="align-left ">{Number(dropPercentage).toFixed(2)+"%"}</td>
                                <td className="align-left " title={ltp}>
                                    {'Rs ' + ltp}
                                </td>
                                <td className="align-left " title={nearestLowPercentage}>
                                    {Number(nearestLowPercentage).toFixed(2)+"%"}
                                </td>
                                <td className="align-left " title={fiftyWeekHigh}>
                                    {fiftyWeekHigh}
                                </td>
                                <td className="align-left " title={fiftyWeekLow}>
                                    {fiftyWeekLow}
                                </td>
                            </tr>
                        )
                    )
                ) : (
                    <tr>
                        <td colSpan="7" className="text-center">
                            No Record(s)
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};
export default connect(null)(withRouter(WatchListTable));
