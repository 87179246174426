// Authentication
export const authenticationActionTypes = {
  SET_ROLE: 'SET_ROLE',
  AUTH_USER: 'AUTH_USER',
  SET_TOKEN: 'SET_TOKEN',
  AUTH_ERROR: 'AUTH_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  AUTH_REDIRECT: 'AUTH_REDIRECT',
  SET_SUBMIT_LOADER: 'SET_SUBMIT_LOADER',
};
