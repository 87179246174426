import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {TopHoldingBroker} from 'components';
import {viewRealTimeByAmount} from 'redux/Realtime/realtime.actions';

const RealTimeTopHoldingBroker = ({
                                      loader,
                                      headers,
                                      buyers,
                                      sellers,
                                      setLoaderState,
                                      token,
                                      processed,
                                      viewRealTimeByAmount,
                                      analysisClick,
                                      brokerClick

                                  }) => {
    useEffect(
        () => {
            const callApi = async () => {
                console.log('here at calling api 2');
                setLoaderState('realTimeTopHoldingBrokerByQuantityLoader', true);
                await viewRealTimeByAmount({token});
                setLoaderState('realTimeTopHoldingBrokerByQuantityLoader', false);
                console.log('here at calling api 2');
            };
            callApi();
        },
        [processed]
    );

    return (
        <TopHoldingBroker
            loader={loader}
            cardTitle={"Realtime broker(s) By Amount"}
            headers={headers}
            buyers={buyers}
            sellers={sellers}
            analysisClick={analysisClick}
            brokerClick={brokerClick}
        />
    );
};

export default connect(null, {
    viewRealTimeByAmount,
})(withRouter(RealTimeTopHoldingBroker));
