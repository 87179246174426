import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {Table} from 'reactstrap';

import HRNumbers from 'human-readable-numbers';

const StockHoldingTable = ({headers, holdings, tableTitle, analysisClick,brokerClick}) => {
    return (
        <>
            <h5>{tableTitle}</h5>
            <Table responsive hover size="sm">
                <thead>
                <tr className="text-capitalize align-left">
                    {headers.map((item, index) => <th key={index}>{item}</th>)}
                </tr>
                </thead>
                <tbody>
                {holdings && holdings.length ? (
                    holdings.map(
                        (
                            {
                                brokerName,
                                brokerCode,
                                symbol,
                                sectorName,
                                totalHolding,
                                holdingPercentage,
                                totalBuy,
                                totalSell,
                                ltp
                            },
                            index
                        ) => (
                            <tr key={index} >
                                <td>{index+1}</td>
                                <td onClick={() => brokerClick(brokerCode)}>{brokerCode}</td>
                                <td className="align-left " title={brokerName} onClick={() => brokerClick(brokerCode)}>{brokerName.split(' ')[0]}</td>
                                <td className="align-left " onClick={() => analysisClick(symbol)}>{symbol}</td>
                                <td className="align-left ">{sectorName}</td>
                                <td className="align-left " title={totalHolding}>
                                    {HRNumbers.toHumanString(totalHolding)}
                                </td>
                                <td className="align-left " title={totalHolding}>
                                    {holdingPercentage + '%'}
                                </td>
                                <td className="align-left " title={totalBuy}>
                                    {HRNumbers.toHumanString(totalBuy)}
                                </td>
                                <td className="align-left " title={totalSell}>
                                    {HRNumbers.toHumanString(totalSell)}
                                </td>
                                {
                                    ltp>0 ? <td className="align-left " title={ltp}>
                                        {`Rs. ${ltp}`}
                                    </td>:null
                                }
                            </tr>
                        )
                    )
                ) : (
                    <tr>
                        <td colSpan="7" className="text-center">
                            No Record(s)
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </>
    );
};
export default connect(null)(withRouter(StockHoldingTable));
