import React from 'react';
import { withRouter } from 'react-router';

import { MdExitToApp } from 'react-icons/md';
import {
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';

import Logo from 'assets/img/logo/logo.png';
import bn from 'utils/bemnames';
import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import { SearchInput } from '..';

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    searchValue: '',
  };
  keyPressed = async e => {
    if (e.key === 'Enter') {
      this.searchClick();
    }
  };
  searchClick = async e => {
    if (window.location.href.includes('analysis')) {
      this.props.history.push(this.state.searchValue);
    } else {
      this.props.history.push('analysis/' + this.state.searchValue);
    }
  };
  onSearch = e => {
    const { name, value } = e.target;
    if (value === '') {
      console.log(value);
    }
    this.setState({ [name]: value });
  };
  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className={bem.e('nav-left')}>
          <img src={Logo} alt="logo" className="logo" />
          <NavItem>
            <NavLink href="/">Home</NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink href="/portfolio">Portfolio</NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink href="/topstockholdingbyduration">
              Top Stock Holding (Duration)
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/strategy">Strategy</NavLink>
          </NavItem>{' '}
          {/* <NavItem>
            <NavLink href="http://peakytraders.unaux.com/" target="_blank">
              Learning Material
            </NavLink>
          </NavItem> */}
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <SearchInput
              key={'search'}
              name={'searchValue'}
              onChange={this.onSearch}
              onKeyPress={this.keyPressed}
              value={this.state.searchValue}
            />
          </NavItem>
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title="Jane"
                  subtitle="jane@jane.com"
                  text="Last updated 3 mins ago"
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}
export default withRouter(Header);
