import axios from 'axios';
import { scriptTypes } from './script.types';
export const URL = process.env.REACT_APP_ANALYZE_URL;

export const callScriptAnalysis = ({ token, symbol }) => async (dispatch) => {
	try {
		let scriptAnalysisResponse;

		if (token) {
			scriptAnalysisResponse = await axios.get(`${URL}/realtime/analysis/${symbol}`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('scriptAnalysisResponse', { scriptAnalysisResponse });

		if (scriptAnalysisResponse.data) {
			dispatch({
				type: scriptTypes.VIEW_REALTIME_SCRIPT_ANALYSIS,
				payload: scriptAnalysisResponse.data
			});
		}
	} catch (e) {
		console.log({ e });
		if (e.response && e.response.data) {
			dispatch({
				type: scriptTypes.VIEW_REALTIME_SCRIPT_ANALYSIS_ERROR,
				payload: e.response.data.error
			});
		}
	}
};

export const callTodayTechnicalAnalysis = ({ token, symbol }) => async (dispatch) => {
	try {
		let scriptAnalysisResponse;

		if (token) {
			scriptAnalysisResponse = await axios.get(`${URL}/realtime/technical/today/${symbol}`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('callTodayTechnicalAnalysis', { scriptAnalysisResponse });

		if (scriptAnalysisResponse.data) {
			dispatch({
				type: scriptTypes.VIEW_REALTIME_SCRIPT_TECHNICAL_ANALYSIS,
				payload: scriptAnalysisResponse.data
			});
		}
	} catch (e) {
		console.log({ e });
		if (e.response && e.response.data) {
			dispatch({
				type: scriptTypes.VIEW_REALTIME_SCRIPT_TECHNICAL_ANALYSIS_ERROR,
				payload: e.response.data.error
			});
		}
	}
};
