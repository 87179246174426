import axios from 'axios';
import { historyActionTypes } from './history.types';
export const URL = process.env.REACT_APP_ANALYZE_URL;

export const viewHistoryByQuantity =
  ({ token, symbol, dateQuery }) =>
  async dispatch => {
    try {
      let historyResponse;
      console.log("viewHistoryByQuantity",token, symbol);

      if (token) {
        let url = `${URL}/realtime/history/${symbol}`;
        if (dateQuery && dateQuery !== '') {
          url += `?date=${dateQuery}`;
        }
        historyResponse = await axios.get(url, {
          headers: { Authorization: `Bearer ` + token },
        });
      }

      console.log('historyResponse', { historyResponse });

      if (historyResponse.data) {
        dispatch({
          type: historyActionTypes.VIEW_HISTORY_BY_QUANTITY,
          payload: historyResponse.data,
        });
      }
    } catch (e) {
      console.log({ e });

      if (e.response && e.response.data) {
        dispatch({
          type: historyActionTypes.VIEW_HISTORY_BY_QUANTITY_ERROR,
          payload: e.response.data.error,
        });
      }
    }
  };

export const viewHistoryBrokerCodeByQuantity =
  ({ token, brokerCode, quantity, dateQuery = '' }) =>
  async dispatch => {
    try {
      let historyResponse;
      console.log(token, brokerCode);

      if (token) {
        let url = `${URL}/realtime/history/broker/${brokerCode}?quantity=${quantity}`;
        if (dateQuery && dateQuery !== '') {
          url += `&date=${dateQuery}`;
        }
        historyResponse = await axios.get(url, {
          headers: { Authorization: `Bearer ` + token },
        });
      }

      console.log('viewHistoryBrokerCodeByQuantity', { historyResponse });

      if (historyResponse.data) {
        if (quantity) {
          dispatch({
            type: historyActionTypes.VIEW_HISTORY_BROKER_WISE_BY_QUANTITY,
            payload: historyResponse.data,
          });
        } else {
          dispatch({
            type: historyActionTypes.VIEW_HISTORY_BROKER_WISE_BY_AMOUNT,
            payload: historyResponse.data,
          });
        }
      }
    } catch (e) {
      console.log({ e });

      if (e.response && e.response.data) {
        dispatch({
          type: historyActionTypes.VIEW_HISTORY_BROKER_WISE_BY_QUANTITY_ERROR,
          payload: e.response.data.error,
        });
      }
    }
  };
export const viewNDayAveragePrice =
  ({ token, symbol }) =>
  async dispatch => {
    try {
      let ndayAvgPriceResponse;
      console.log(token, symbol);

      if (token) {
        let url = `${URL}/realtime/history/ntradingdays/${symbol}`;

        ndayAvgPriceResponse = await axios.get(url, {
          headers: { Authorization: `Bearer ` + token },
        });
      }

      console.log('ndayAvgPriceResponse', { ndayAvgPriceResponse });
      if (ndayAvgPriceResponse.data) {
        dispatch({
          type: historyActionTypes.VIEW_N_DAY_AVERAGE_PRICE,
          payload: ndayAvgPriceResponse.data,
        });
      }
    } catch (e) {
      console.log({ e });

      if (e.response && e.response.data) {
        dispatch({
          type: historyActionTypes.VIEW_N_DAY_AVERAGE_PRICE_ERROR,
          payload: e.response.data.error,
        });
      }
    }
  };
