import React from 'react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {viewRealTimBrokerWise} from 'redux/Realtime/realtime.actions';
import {viewHistoryBrokerCodeByQuantity} from 'redux/History/history.actions';
import {viewTopBrokerHoldingByDurationByBrokerCode} from 'redux/TopBrokerHoldingByDuration/topbrokerholdingbyduration.actions';
import {
    BrokerHoldingByDuration,
    HistoryTopHoldingBrokerAnalysis,
    Loader,
    Page,
    RealtimeTopHoldingBrokerAnalysis, StockHolding,
} from '../components';
import {registration, unsubscribe} from '../hocs/websocket-listener';
import {viewStockHoldingByBrokerCode} from "../redux/StockHolding/stockholding.actions";

class BrokerByCodePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            realTimeTopHoldingBrokerAnalysisLoader: false,
            realTimeTopHoldingBrokerAnalysisLoaderAmount: false,
            historyTopHoldingBrokerAnalysisLoaderAmount: false,
            historyTopHoldingBrokerAnalysisLoader: false,
            processed: 0,
            brokerCode: '',
            durationLoader: false,
            qtydateQuery: '',
            amountdateQuery: '',
        };
    }

    async componentDidMount() {
        // registration([
        //   {
        //     route: '/queue/mrmarketui.queue.floorsheet',
        //     callback: this.refreshFloorsheetChanged,
        //   },
        // ]);
    }

    componentWillUnmount() {
        // unsubscribe([
        //   {
        //     route: '/queue/mrmarketui.queue.floorsheet',
        //     callback: this.refreshFloorsheetChanged,
        //   },
        // ]);
    }

    setLoaderState = (loaderName, loaderState) => {
        this.setState({[loaderName]: loaderState});
    };

    refreshFloorsheetChanged = async message => {
        this.setState({processed: parseInt(message.body)});
    };

    analysisClick = symbol => {
        console.log(symbol);
        this.props.history.push('../analysis/' + symbol);
    };
    dateChange = event => {
        console.log(event.target.name);
        if (event.target.name == 'qtyDate') {
            console.log(event.target.value);
            this.setState({
                qtydateQuery: event.target.value,
            });
        } else {
            this.setState({
                amountdateQuery: event.target.value,
            });
        }
    };

    render() {
        return (
            <Page
                className="Broker Analysis"
                title="Broker Analysis"
                breadcrumbs={[
                    {name: 'Broker Analysis', active: false},
                    {name: this.props.match.params.symbol, active: true},
                ]}
            >
                <RealtimeTopHoldingBrokerAnalysis
                    key="1"
                    loader={this.state.realTimeTopHoldingBrokerAnalysisLoader}
                    setLoaderState={this.setLoaderState}
                    headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount']}
                    buyers={this.props.qBuyers}
                    sellers={this.props.qSellers}
                    token={this.props.token}
                    quantity={true}
                    processed={this.state.processed}
                    brokerCode={this.props.match.params.brokerCode}
                    analysisClick={this.analysisClick}
                    loaderStr={'realTimeTopHoldingBrokerAnalysisLoader'}
                />
                <RealtimeTopHoldingBrokerAnalysis
                    key="2"
                    loader={this.state.realTimeTopHoldingBrokerAnalysisLoaderAmount}
                    setLoaderState={this.setLoaderState}
                    headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount']}
                    buyers={this.props.qbrokerWiseAmountBuyers}
                    sellers={this.props.qbrokerWiseAmountSellers}
                    token={this.props.token}
                    quantity={false}
                    processed={this.state.processed}
                    brokerCode={this.props.match.params.brokerCode}
                    analysisClick={this.analysisClick}
                    loaderStr={'realTimeTopHoldingBrokerAnalysisLoaderAmount'}
                />
                <HistoryTopHoldingBrokerAnalysis
                    key="3"
                    loader={this.state.historyTopHoldingBrokerAnalysisLoader}
                    setLoaderState={this.setLoaderState}
                    headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount']}
                    buyers={this.props.brokerBuyers}
                    sellers={this.props.brokerSellers}
                    token={this.props.token}
                    quantity={true}
                    loaderStr="historyTopHoldingBrokerAnalysisLoader"
                    brokerCode={this.props.match.params.brokerCode}
                    analysisClick={this.analysisClick}
                    dateChange={this.dateChange}
                    dateQuery={this.state.qtydateQuery}
                />
                <HistoryTopHoldingBrokerAnalysis
                    key="4"
                    loader={this.state.historyTopHoldingBrokerAnalysisLoaderAmount}
                    setLoaderState={this.setLoaderState}
                    headers={['Code', 'Broker', 'Symbol', 'Count', 'Amount']}
                    buyers={this.props.qbrokerWiseHistoryAmountBuyers}
                    sellers={this.props.qbrokerWiseHistoryAmountSellers}
                    token={this.props.token}
                    quantity={false}
                    brokerCode={this.props.match.params.brokerCode}
                    loaderStr="historyTopHoldingBrokerAnalysisLoaderAmount"
                    analysisClick={this.analysisClick}
                    dateChange={this.dateChange}
                    dateQuery={this.state.amountdateQuery}
                />
                <StockHolding
                    loader={this.state.stockHoldingLoader}
                    setLoaderState={this.setLoaderState}
                    headers={[
                        'SN.',
                        'Code',
                        'Broker',
                        'Symbol',
                        'Sector',
                        'Holding',
                        'Holding (%)',
                        'Buy',
                        'Sell',
                        'Ltp.'
                    ]}
                    token={this.props.token}
                    holdings={this.props.holdings}
                    stockHoldingCounter={this.state.stockHoldingCounter}
                    analysisClick={this.analysisClick}
                    brokerClick={this.brokerClick}
                    brokerCode={this.props.match.params.brokerCode}
                />
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.authenticated,
        qBuyers: state.realtime.brokerWiseBuyers,
        qSellers: state.realtime.brokerWiseSellers,
        brokerBuyers: state.overallAnalysis.brokerBuyers,
        brokerSellers: state.overallAnalysis.brokerSellers,
        qbrokerWiseAmountBuyers: state.realtime.brokerWiseAmountBuyers,
        qbrokerWiseAmountSellers: state.realtime.brokerWiseAmountSellers,
        qbrokerWiseHistoryAmountBuyers: state.overallAnalysis.brokerAmountBuyers,
        qbrokerWiseHistoryAmountSellers: state.overallAnalysis.brokerAmountSellers,
        // durationData: state.topbrokerholdingbyduration.brokerData,
    };
}

export default connect(mapStateToProps, {
    viewRealTimBrokerWise,
    viewHistoryBrokerCodeByQuantity,
    // viewTopBrokerHoldingByDurationByBrokerCode,
})(withRouter(BrokerByCodePage));
