import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {Card, CardBody, CardHeader} from 'reactstrap';
import {viewTopSectorwiseStockHolding} from 'redux/StockHolding/stockholding.actions';

import {Loader, StockHoldingTable} from 'components';

const SectorwiseStockHolding = ({
                                    loader,
                                    headers,
                                    setLoaderState,
                                    stockHoldingCounter,
                                    token,
                                    sectorwises,
                                    analysisClick,
                                    brokerClick,
                                    viewTopSectorwiseStockHolding,
                                }) => {
    useEffect(
        () => {
            const callApi = async () => {
                setLoaderState('sectorWiseLoader', true);
                await viewTopSectorwiseStockHolding({token});
                setLoaderState('sectorWiseLoader', false);
            };
            callApi();
        },
        [stockHoldingCounter]
    );
    return (
        <Card>
            <CardHeader>Sector Wise Stock Holding</CardHeader>
            <CardBody>
                {loader ? (
                    <Loader/>
                ) : (
                    <>
                        {sectorwises && Object.keys(sectorwises).length > 0 ? (
                                Object.keys(sectorwises).map((key) => (
                                    <StockHoldingTable
                                        holdings={sectorwises[key]}
                                        tableTitle={key}
                                        key={key}
                                        headers={headers}
                                        analysisClick={analysisClick}
                                        brokerClick={brokerClick}
                                    />
                                ))
                            ) :
                            (
                                <div className="col-12 no-record">No Record(s)</div>
                            )}
                    </>
                )}
            </CardBody>
        </Card>
    );
};
export default connect(null, {
    viewTopSectorwiseStockHolding
})(withRouter(SectorwiseStockHolding));
