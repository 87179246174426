import reducers from 'redux/root-reducer/root-reducer';
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

export const store = createStore(
  reducers,
  {
    auth: {
      authenticated: localStorage.getItem('token'),
    },
  },
  composeWithDevTools(applyMiddleware(reduxThunk)),
);
