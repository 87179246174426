
import React from 'react';

import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, roleToBeChecked, role, auth, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				auth && localStorage.getItem('role') === roleToBeChecked ? (
					<Component {...props} />
				) : (
					<Redirect to="/login" />
				)}
		/>
	);
};

const mapStateToProps = state => ({
    role : state.auth.role,
    auth : state.auth.authenticated 
});

export default connect(mapStateToProps)(PrivateRoute);
