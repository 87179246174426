import { historyActionTypes } from './history.types';
const initialState = {
  qtyBuyers: [],
  qtySellers: [],
  brokerBuyers: [],
  brokerSellers: [],
  brokerAmountBuyers: [],
  brokerAmountSellers: [],
  navgPrices: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case historyActionTypes.VIEW_HISTORY_BY_QUANTITY:
      return {
        ...state,
        qtyBuyers: action.payload.buyers,
        qtySellers: action.payload.sellers,
      };
    case historyActionTypes.VIEW_HISTORY_BROKER_WISE_BY_QUANTITY:
      return {
        ...state,
        brokerBuyers: action.payload.buyers,
        brokerSellers: action.payload.sellers,
      };
    case historyActionTypes.VIEW_HISTORY_BROKER_WISE_BY_AMOUNT:
      return {
        ...state,
        brokerAmountBuyers: action.payload.buyers,
        brokerAmountSellers: action.payload.sellers,
      };
    case historyActionTypes.VIEW_N_DAY_AVERAGE_PRICE:
      return {
        ...state,
        navgPrices: action.payload,
      };
    case historyActionTypes.VIEW_HISTORY_BY_QUANTITY_ERROR:
    case historyActionTypes.VIEW_HISTORY_BROKER_WISE_BY_QUANTITY_ERROR:
    case historyActionTypes.VIEW_N_DAY_AVERAGE_PRICE_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
}
