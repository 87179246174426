import {watchlistTypes} from './watchlist.types';

const initialState = {
    aboveFifty: [],
    aboveThirty: [],
    summary:[],
    errorMessage: ''
};
export default function (state = initialState, action) {
    switch (action.type) {
        case watchlistTypes.VIEW_WATCHLIST:
            return {
                ...state,
                aboveFifty: action.payload["aboveFifty"],
                aboveThirty: action.payload["aboveThirty"],
                summary:action.payload["summary"]
            };
        case watchlistTypes.VIEW_WATCHLIST_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
}
