import axios from 'axios';
import { realTimeActionTypes } from './realtime.types';
export const URL = process.env.REACT_APP_ANALYZE_URL;

export const viewRealTimeByAmount = ({ token }) => async (dispatch) => {
	try {
		let realTimeTHBResponse;

		if (token) {
			realTimeTHBResponse = await axios.get(`${URL}/realtime/analysis/topholdingbrokerbyamount`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('realTimeTHBResponse', { realTimeTHBResponse });

		if (realTimeTHBResponse.data) {
			dispatch({
				type: realTimeActionTypes.VIEW_REALTIME_THB,
				payload: realTimeTHBResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: realTimeActionTypes.VIEW_REALTIME_THB_ERROR,
				payload: e.response.data.error
			});
		}
	}
};

export const viewRealTimeByQuantity = ({ token }) => async (dispatch) => {
	try {
		let realTimeTHBResponse;

		if (token) {
			realTimeTHBResponse = await axios.get(`${URL}/realtime/analysis/topholdingbrokerbyquantity`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('realTimeTHBResponse', { realTimeTHBResponse });

		if (realTimeTHBResponse.data) {
			dispatch({
				type: realTimeActionTypes.VIEW_REALTIME_BY_QUANTITY,
				payload: realTimeTHBResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: realTimeActionTypes.VIEW_REALTIME_BY_QUANTITY_ERROR,
				payload: e.response.data.error
			});
		}
	}
};

export const viewRealTimePortfolioAnalysis = ({ token }) => async (dispatch) => {
	try {
		let portfolioAnalysisResponse;

		if (token) {
			portfolioAnalysisResponse = await axios.get(`${URL}/realtime/analysis/pa`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('portfolioAnalysisResponse', { portfolioAnalysisResponse });

		if (portfolioAnalysisResponse.data) {
			dispatch({
				type: realTimeActionTypes.VIEW_REALTIME_PA,
				payload: portfolioAnalysisResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: realTimeActionTypes.VIEW_REALTIME_PA_ERROR,
				payload: e.response.data.error
			});
		}
	}
};

export const viewRealTimBrokerWise = ({ token, brokerCode, quantity }) => async (dispatch) => {
	try {
		let portfolioAnalysisResponse;

		if (token) {
			portfolioAnalysisResponse = await axios.get(
				`${URL}/realtime/analysis/broker/${brokerCode}?quantity=${quantity}`,
				{
					headers: { Authorization: `Bearer ` + token }
				}
			);
		}

		console.log('viewRealTimBrokerWise', { portfolioAnalysisResponse });

		if (portfolioAnalysisResponse.data) {
			if(quantity){
				dispatch({
					type: realTimeActionTypes.VIEW_REALTIME_BROKERWISE,
					payload: portfolioAnalysisResponse.data
				});
			}else{
				dispatch({
					type: realTimeActionTypes.VIEW_REALTIME_BROKERWISE_BY_AMOUNT,
					payload: portfolioAnalysisResponse.data
				});
			}
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: realTimeActionTypes.VIEW_REALTIME_BROKERWISE_ERROR,
				payload: e.response.data.error
			});
		}
	}
};
