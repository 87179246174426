import { realTimeActionTypes } from './realtime.types';
const initialState = {
	buyers: [],
	sellers: [],
	qtyBuyers : [],
	qtySellers : [],
	brokerWiseBuyers : [],
	brokerWiseSellers : [],
	brokerWiseAmountBuyers :[],
	brokerWiseAmountSellers :[],
	errorMessage: '',
	portfolioAnalysis: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case realTimeActionTypes.VIEW_REALTIME_THB:
			return {
				...state,
				buyers: action.payload.buyers,
				sellers: action.payload.sellers
			};
		case realTimeActionTypes.VIEW_REALTIME_BY_QUANTITY:
			return {
				...state,
				qtyBuyers: action.payload.buyers,
				qtySellers: action.payload.sellers
		};
		case realTimeActionTypes.VIEW_REALTIME_BROKERWISE:
			return {
				...state,
				brokerWiseBuyers: action.payload.buyers,
				brokerWiseSellers: action.payload.sellers
			};
		case realTimeActionTypes.VIEW_REALTIME_BROKERWISE_BY_AMOUNT:
			return {
				...state,
				brokerWiseAmountBuyers: action.payload.buyers,
				brokerWiseAmountSellers: action.payload.sellers
			};
		case realTimeActionTypes.VIEW_REALTIME_THB_ERROR:
		case realTimeActionTypes.VIEW_REALTIME_BY_QUANTITY_ERROR:
		case realTimeActionTypes.VIEW_REALTIME_PA_ERROR:
		case realTimeActionTypes.VIEW_REALTIME_BROKERWISE_ERROR:
		case realTimeActionTypes.VIEW_REALTIME_BROKERWISE_BY_AMOUNT_ERROR:
			return {
				...state,
				errorMessage: action.payload
			};
		case realTimeActionTypes.VIEW_REALTIME_PA:
			return {
				...state,
				portfolioAnalysis: action.payload
			};
			
		default:
			return state;
	}
}
