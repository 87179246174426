import { Loader } from 'components';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

const headers = [
  {
    label: 'tracker',
    title: 'track it',
  },
  {
    label: 'SN',
    title: 'SN',
  },
  {
    label: 'Name',
    title: 'Name',
  },
  {
    label: 'E.No',
    title: 'Number of Entry',
  },
  {
    label: 'W.No',
    title: 'Number of Winning',
  },
  {
    label: 'L.No',
    title: 'Number of Losing',
  },
  {
    label: 'B.D',
    title: 'Buy Date',
  },
  {
    label: 'B.R',
    title: 'Buy (Rs)',
  },
  {
    label: 'S.D',
    title: 'Sell Date',
  },
  {
    label: 'S.R',
    title: 'Sell (Rs)',
  },
  {
    label: 'SP/L(%)',
    title: 'Profit/Loss(%)',
  },
  {
    label: 'Dur',
    title: 'Duration',
  },
  {
    label: 'P/L(%)',
    title: 'Entry Exit Profit/Loss(%)',
  },

  {
    label: 'W.R',
    title: 'Winning positions ratio',
  },
  {
    label: 'M.D',
    title: 'Maximum drawdown',
  },
  {
    label: 'R.O.M.D',
    title: 'Return over maximum drawdown',
  },
  {
    label: 'NOP',
    title: 'Number of positions',
  },

  {
    label: 'NOB',
    title: 'Number of bars',
  },
];
const Strategy = ({ loader, cardTitle, strategies, trackStrategy, tableFixed = true }) => {
  console.log(trackStrategy);
  const [filterText, setFilterText] = useState('');
  const filterItems = strategies && strategies.filter(s =>
    s.strategyName.toLowerCase().includes(filterText.toLowerCase()),
  );

  return (
    <Card className="mt-1">
      <CardHeader>
        {cardTitle}
        <input
          className="form-control"
          placeholder="search"
          value={filterText}
          onChange={e => setFilterText(e.target.value)}
        />
      </CardHeader>
      <CardBody>
        {loader ? (
          <Loader />
        ) : (
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className={
                tableFixed ? "tableFixHead" : "table-responsive"
              }>
                <Table size="sm">
                  <thead className={'thead-dark'}>
                    <tr className="text-capitalize align-left">
                      {headers.map((item, index) => (
                        <th key={index} title={item.title}>
                          {item.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filterItems && filterItems.length ? (
                      filterItems.sort((a, b) => {
                        // Compare the entryExitPercentage values
                        if (a.entryExitPercentage < b.entryExitPercentage) {
                          return 1; // For descending order, return 1 if a < b
                        } else if (a.entryExitPercentage > b.entryExitPercentage) {
                          return -1; // Return -1 if a > b
                        }
                        return 0; // Return 0 if they are equal
                      }).map(
                        (
                          {
                            positions,
                            lastEntry,
                            strategyName,
                            numberOfPosition,
                            symbolName,
                            totalReturn,
                            numberOfBar,
                            avgReturnPerBar,
                            winningPositionRatio,
                            maximumDrawDown,
                            returnOverMaximumDrawDown,
                            totalTransactionCost,
                            buyHoldReturnCriteria,
                            versusBuyAndHold,
                            entryExitPercentage,
                          },
                          index,
                        ) => {
                          const entryOrBreakEven = positions.filter(
                            p => p.netProfitOrLoss == 0,
                          ).length;
                          const winning = positions.filter(
                            p => p.netProfitOrLoss > 0,
                          ).length;
                          const losing = positions.filter(
                            p => p.netProfitOrLoss < 0,
                          ).length;
                          return positions.map((position, inde) => (
                            <tr key={inde}>
                              {inde === 0 ? (
                                <React.Fragment>
                                  {
                                    <td>
                                      <button className={'btn btn-sm btn-primary'} onClick={() => trackStrategy(strategyName)}>Track it?</button>
                                    </td>
                                  }

                                  <td>
                                    {index + 1}.{inde + 1}
                                  </td>
                                  <td>
                                    <strong>{strategyName}</strong>
                                  </td>
                                  <td>{entryOrBreakEven}</td>
                                  <td>{winning}</td>
                                  <td>{losing}</td>
                                  <td>
                                    {moment(
                                      position.entry.date.split('[')[0],
                                    ).format('DD, MMM Y')}{' '}
                                  </td>
                                  <td>{Math.round(position.entry.price)}</td>
                                  {position.exit ? (
                                    <React.Fragment>
                                      <td>
                                        {moment(
                                          position.exit.date.split('[')[0],
                                        ).format('DD, MMM Y')}
                                      </td>
                                      <td>{Math.round(position.exit.price)}</td>

                                      <td>
                                        {Number(
                                          ((position.exit.price -
                                            position.entry.price) /
                                            position.exit.price) *
                                          100,
                                        ).toFixed(1)}
                                      </td>
                                      <td>
                                        {moment(
                                          position.exit.date.split('[')[0],
                                        ).diff(
                                          moment(
                                            position.entry.date.split('[')[0],
                                          ),
                                          'days',
                                        )}
                                      </td>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <td>Not Found</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                    </React.Fragment>
                                  )}
                                  <td>
                                    {Number(entryExitPercentage).toFixed(2)}{' '}
                                  </td>{' '}
                                  <td
                                    className={
                                      'align-left  ' +
                                      (winningPositionRatio >= 0.7
                                        ? 'text-white bg-success'
                                        : 'bg-default')
                                    }
                                  >
                                    {Number(winningPositionRatio).toFixed(1)}{' '}
                                  </td>
                                  <td>{Number(maximumDrawDown).toFixed(1)} </td>{' '}
                                  <td>
                                    {Number(returnOverMaximumDrawDown).toFixed(
                                      1,
                                    )}{' '}
                                  </td>{' '}
                                  <td>{numberOfPosition} </td>
                                  <td>{numberOfBar} </td>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <td></td>

                                  <td>
                                    {index + 1}.{inde + 1}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {moment(
                                      position.entry.date.split('[')[0],
                                    ).format('DD, MMM Y')}{' '}
                                  </td>
                                  <td>{Math.round(position.entry.price)}</td>
                                  {position.exit ? (
                                    <React.Fragment>
                                      <td>
                                        {moment(
                                          position.exit.date.split('[')[0],
                                        ).format('DD, MMM Y')}
                                      </td>
                                      <td>{Math.round(position.exit.price)}</td>
                                      <td>
                                        {Number(
                                          ((position.exit.price -
                                            position.entry.price) /
                                            position.exit.price) *
                                          100,
                                        ).toFixed(1)}
                                      </td>
                                      <td>
                                        {moment(
                                          position.exit.date.split('[')[0],
                                        ).diff(
                                          moment(
                                            position.entry.date.split('[')[0],
                                          ),
                                          'days',
                                        )}
                                      </td>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                    </React.Fragment>
                                  )}
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </React.Fragment>
                              )}
                            </tr>
                          ));
                        },
                      )
                    ) : (
                      <tr>
                        <td colSpan="6" className="">
                          No Record(s)
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default connect(null)(withRouter(Strategy));
