import { scriptTypes } from './script.types';
const initialState = {
	buyers: [],
	sellers: [],
	errorMessage: '',
	portfolioAnalysis: {},
	priceDifference : {},
	dailyTrade:{},
	todayTechnical: [],
	strategies:[],
	uptostrategies:[]
};

export default function(state = initialState, action) {
	switch (action.type) {
		case scriptTypes.VIEW_REALTIME_SCRIPT_ANALYSIS:
			return {
				...state,
				portfolioAnalysis: action.payload.company,
				priceDifference: action.payload.priceDifference,
				dailyTrade:action.payload.dailyTrade
			};
		case scriptTypes.VIEW_REALTIME_SCRIPT_TECHNICAL_ANALYSIS:
			return {
				...state,
				todayTechnical: action.payload.ta,
				strategies:action.payload.strategies,
				uptostrategies:action.payload.uptoStrategies
			};
		case scriptTypes.VIEW_REALTIME_SCRIPT_TECHNICAL_ANALYSIS_ERROR:
		case scriptTypes.VIEW_REALTIME_SCRIPT_ANALYSIS_ERROR:
			return {
				...state,
				errorMessage: action.payload
			};
		default:
			return state;
	}
}
