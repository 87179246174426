import {addStrategyTracker, getTechnicalSummary} from 'api/realtime';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Table } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';
import {useMutation} from 'react-query';

const SummaryTable = ({
  headers,
  summary,
  tableTitle,
  analysisClick,
  technicalClick,
  token,  history

}) => {
  const { data, isError, error, isLoading, isFetched } = useQuery(
    'TechnicalSummary',
    () => getTechnicalSummary(token),
    {
      staleTime: 2000, //3 min
      keepPreviousData: true,
      refetchInterval: 300000,
      refetchIntervalInBackground: true,
    },
  );
  const onSuccess = (symbol, strategyName) => {
    console.log(`strategy-tracker?symbol=${symbol}&strategyName=${strategyName}`);
    history.push(`strategy-tracker?symbol=${symbol}&strategyName=${strategyName}`);
  };
  const trackerMutation = useMutation((data) => addStrategyTracker(token, data));
  const trackStrategy = async (symbol, strategyName) => {
    console.log("trackStrategy")


    await trackerMutation.mutate({ symbol, strategyName }, {
      onSuccess: () => onSuccess(symbol, strategyName)
    });
  }
  console.log('data sss', data);
  return isLoading ? (
    <div>Loading....</div>
  ) : (
    <Card>
      <CardHeader>
        <h3>{tableTitle}</h3>
      </CardHeader>
      <CardBody>
        <Table responsive hover size="sm">
          <thead>
            <tr className="text-capitalize align-left">
              {headers.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.data && data.data.length ? (
              data.data
                .sort(
                  (a, b) =>
                    a.values[0].averageReturnDays -
                    b.values[0].averageReturnDays,
                )
                .map(({ symbol, count, values, sectorName, ltp }, indexD) =>
                  values.map((v, index) => {
                    if (index === 0) {
                      return (
                        <tr key={index} rowSpan={count}>
                            <td
                                className="align-left "
                                title={symbol}
                                rowSpan={count}>
                                <button className={'btn btn-sm btn-primary'} onClick={()=>trackStrategy(symbol,v.name)}>Track it?</button>
                            </td>
                          <td
                            className="align-left "
                            title={symbol}
                            rowSpan={count}
                          >
                            {indexD + 1}
                          </td>{' '}
                          <td
                            className="align-left "
                            title={symbol}
                            rowSpan={count}
                          >
                            {symbol}
                          </td>
                          <td
                            className="align-left "
                            title={sectorName}
                            rowSpan={count}
                          >
                            {sectorName}
                          </td>
                          <td className="align-left" rowSpan={count}>
                            {ltp}
                          </td>
                          <React.Fragment>
                            <td className="align-left">{v.name}</td>
                            <td
                              className={
                                'align-left ' +
                                (v.maximumDrawDown < 0.2
                                  ? 'bg-success'
                                  : 'bg-default')
                              }
                            >
                              {Number(v.maximumDrawDown).toFixed(1)}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (v.returnOverMaximumDrawDown > 16
                                  ? 'bg-success'
                                  : 'bg-default')
                              }
                            >
                              {Number(v.returnOverMaximumDrawDown).toFixed(1)}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (v.winningPositionRatio >= 0.8
                                  ? 'bg-success'
                                  : 'bg-default')
                              }
                            >
                              {v.winningPositionRatio}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (v.returnPercentage >= 90
                                  ? 'bg-success'
                                  : 'bg-default')
                              }
                            >
                              {v.returnPercentage}
                            </td>
                            <td className="align-left">{v.price}</td>
                            <td
                              className={
                                'align-left ' +
                                (Number(((ltp - v.price) / ltp) * 100).toFixed(
                                  1,
                                ) >= 8
                                  ? 'bg-success'
                                  : 'bg-default')
                              }
                            >
                              {Number(((ltp - v.price) / ltp) * 100).toFixed(1)}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (v.noWinning / v.noLosing >= 2
                                  ? 'bg-success'
                                  : v.noWinning > v.noLosing
                                  ? 'bg-default'
                                  : 'bg-danger')
                              }
                            >
                              {v.noEntry}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (v.noWinning / v.noLosing >= 2
                                  ? 'bg-success'
                                  : v.noWinning > v.noLosing
                                  ? 'bg-default'
                                  : 'bg-danger')
                              }
                            >
                              {v.noWinning}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (v.noWinning / v.noLosing >= 2
                                  ? 'bg-success'
                                  : v.noWinning > v.noLosing
                                  ? 'bg-default'
                                  : 'bg-danger')
                              }
                            >
                              {v.noLosing}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (v.averageReturnDays != 0 &&
                                v.averageReturnDays <= 5
                                  ? 'bg-success'
                                  : 'bg-default')
                              }
                            >
                              {v.averageReturnDays}
                            </td>
                            <td
                              className={
                                'align-left ' +
                                (moment().diff(v.date.split('T')[0], 'days') ===
                                0
                                  ? 'bg-success'
                                  : 'bg-default')
                              }
                            >
                              {v.date.split('T')[0]}
                            </td>
                          </React.Fragment>
                          <td>
                            <div className="buttons">
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => analysisClick(symbol)}
                              >
                                Analysis
                              </button>
                              <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => technicalClick(symbol)}
                              >
                                Technical
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td className="align-left">{v.name}</td>
                          <td
                            className={
                              'align-left ' +
                              (v.maximumDrawDown < 0.2
                                ? 'bg-success'
                                : 'bg-default')
                            }
                          >
                            {Number(v.maximumDrawDown).toFixed(1)}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (v.returnOverMaximumDrawDown > 16
                                ? 'bg-success'
                                : 'bg-default')
                            }
                          >
                            {Number(v.returnOverMaximumDrawDown).toFixed(1)}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (v.winningPositionRatio >= 0.8
                                ? 'bg-success'
                                : 'bg-default')
                            }
                          >
                            {v.winningPositionRatio}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (v.returnPercentage >= 90
                                ? 'bg-success'
                                : 'bg-default')
                            }
                          >
                            {v.returnPercentage}
                          </td>
                          <td className="align-left">{v.price}</td>
                          <td
                            className={
                              'align-left ' +
                              (Number(((ltp - v.price) / ltp) * 100).toFixed(
                                1,
                              ) >= 8
                                ? 'bg-success'
                                : 'bg-default')
                            }
                          >
                            {Number(((ltp - v.price) / ltp) * 100).toFixed(1)}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (v.noWinning / v.noLosing >= 2
                                ? 'bg-success'
                                : v.noWinning > v.noLosing
                                ? 'bg-default'
                                : 'bg-danger')
                            }
                          >
                            {v.noEntry}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (v.noWinning / v.noLosing >= 2
                                ? 'bg-success'
                                : v.noWinning > v.noLosing
                                ? 'bg-default'
                                : 'bg-danger')
                            }
                          >
                            {v.noWinning}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (v.noWinning / v.noLosing >= 2
                                ? 'bg-success'
                                : v.noWinning > v.noLosing
                                ? 'bg-default'
                                : 'bg-danger')
                            }
                          >
                            {v.noLosing}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (v.averageReturnDays != 0 &&
                              v.averageReturnDays <= 5
                                ? 'bg-success'
                                : 'bg-default')
                            }
                          >
                            {v.averageReturnDays}
                          </td>
                          <td
                            className={
                              'align-left ' +
                              (moment().diff(v.date.split('T')[0], 'days') === 0
                                ? 'bg-success'
                                : 'bg-default')
                            }
                          >
                            {v.date.split('T')[0]}
                          </td>
                        </tr>
                      );
                    }
                  }),
                )
            ) : (
              <tr>
                <td colSpan="11" className="text-center">
                  No Record(s)
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
export default connect(null)(withRouter(SummaryTable));
