import { authenticationActionTypes } from './authentication.types';

const INITIAL_STATE = {
	role: '',
	userToken: '',
	errorMessage: '',
	authenticated: '',
	submitLoader: false
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case authenticationActionTypes.AUTH_USER:
		case authenticationActionTypes.SET_TOKEN:
			return {
				...state,
				authenticated: action.payload
			};

		case authenticationActionTypes.CLEAR_ERROR:
		case authenticationActionTypes.AUTH_ERROR:
			return {
				...state,
				errorMessage: action.payload
			};

		case authenticationActionTypes.AUTH_REDIRECT:
			return {
				...state,
				authenticated: false,
				errorMessage: action.payload
			};

		case authenticationActionTypes.SET_ROLE:
			return {
				...state,
				role: action.payload
			};

		case authenticationActionTypes.SET_SUBMIT_LOADER:
			return {
				...state,
				submitLoader: action.payload
			};

		default:
			return state;
	}
}
