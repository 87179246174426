import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import authenticationReducer from 'redux/Authentication/authentication.reducers';

import realtimeReducer from 'redux/Realtime/realtime.reducers';
import historyReducer from 'redux/History/history.reducers';
import scriptReducer from 'redux/Script/script.reducers';
import stockHoldingReducer from 'redux/StockHolding/stockholding.reducers';
import watchListReducer from 'redux/WatchList/watchlist.reducers';
import topBrokerHoldingByDurationReducer from 'redux/TopBrokerHoldingByDuration/topbrokerholdingbyduration.reducers';

export default combineReducers({
    auth: authenticationReducer,
    realtime: realtimeReducer,
    overallAnalysis:historyReducer,
    script: scriptReducer,
    stockholding: stockHoldingReducer,
    watchlist: watchListReducer,
    topbrokerholdingbyduration: topBrokerHoldingByDurationReducer,
    form: formReducer
});
