import axios from 'axios';

export const URL = process.env.REACT_APP_ANALYZE_URL;
export function getTopHoldingBrokerByQuantity(token, isQuantity,date ="") {
  if (isQuantity) {
    return axios.get(`${URL}/realtime/analysis/topholdingbrokerbyquantity?date=${date}`, {
      headers: { Authorization: `Bearer ` + token },
    });
  }
  return axios.get(`${URL}/realtime/analysis/topholdingbrokerbyamount?date=${date}`, {
    headers: { Authorization: `Bearer ` + token },
  });
}
export function getStockHolding(token, page = 0) {
  return axios.get(`${URL}/realtime/analysis/topholding/${page}`, {
    headers: { Authorization: `Bearer ` + token },
  });
}
export function getStockHoldingByBrokerCode(token,brokerCode, page = 0) {
  return axios.get(`${URL}/realtime/analysis/topholding/bybroker/${brokerCode}`, {
    headers: { Authorization: `Bearer ` + token },
  });
}
export function getTechnicalSummary(token) {
  return axios.get(`${URL}/realtime/technical/summary`, {
    headers: { Authorization: `Bearer ` + token },
  });
}

export function getTopVolumeStock(token, page = 0, size = 10, top = 5) {
  return axios.get(
    `${URL}/realtime/analysis/topholding/volumewise/${page}/${size}/${top}`,
    {
      headers: { Authorization: `Bearer ` + token },
    },
  );
}
export function getTechnicalAnalysisBySymbol(token, symbol) {
  return axios.get(`${URL}/realtime/technical/today/${symbol}`, {
    headers: { Authorization: `Bearer ` + token },
  });
}

export function getStrategyTracker(token, data) {
  return axios.get(`${URL}/realtime/technical/getStrategyTracker`, {
    params:data,
    headers: { Authorization: `Bearer ` + token },
  });
}

export function addStrategyTracker(token, data) {
  return axios.put(`${URL}/realtime/technical/add-strategy-tracker`, data,{
    headers: { Authorization: `Bearer ` + token },

  });
}

