import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { viewStockHolding } from 'redux/StockHolding/stockholding.actions';
import { Loader, StockHoldingTable } from 'components';
import { useQuery } from 'react-query';
import {getStockHolding, getStockHoldingByBrokerCode} from 'api/realtime';
const StockHolding = ({
  headers,
  token,
  holdings,
  analysisClick,
  brokerClick,
    brokerCode
}) => {
  const { data, isError, error, isLoading, isFetched } = useQuery(
    'StockHolding',
    () => brokerCode ?getStockHoldingByBrokerCode(token,brokerCode):getStockHolding(token),
    {
      staleTime: 2000, //5 min
      keepPreviousData: true,
      refetchInterval: 300000,
      refetchIntervalInBackground: true,
    },
  );
  // if (isFetched) {
  //   if (data.data) {
  //     console.log('data', data.data);
  //   }
  // }
  if (isError) {
    return <div>{error.toString()}</div>;
  }
  return (
    <Card>
      <CardHeader>Stock Holding</CardHeader>
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <StockHoldingTable
            holdings={data.data.content}
            headers={headers}
            analysisClick={analysisClick}
            brokerClick={brokerClick}
            tableTitle="Top Stock Holding"
          />
        )}
      </CardBody>
    </Card>
  );
};
export default connect(null, {
  viewStockHolding,
})(withRouter(StockHolding));
