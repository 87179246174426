import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TopHoldingBroker } from 'components';
import { viewRealTimBrokerWise } from 'redux/Realtime/realtime.actions';

const RealTimeTopHoldingBrokerAnalysis = ({
	loader,
	headers,
	buyers,
	sellers,
	setLoaderState,
	token,
	processed,
	brokerCode,
	quantity,
	viewRealTimBrokerWise,
	loaderStr,
	analysisClick
}) => {
	useEffect(
		() => {
			const callApi = async () => {
				console.log('here at calling api 2',quantity);
				setLoaderState(loaderStr, true);
				await viewRealTimBrokerWise({ token, brokerCode, quantity });
				setLoaderState(loaderStr, false);
				console.log('here at calling api 2');
			};
			callApi();
		},
		[ processed ]
	);

	return (
		<TopHoldingBroker
			loader={loader}
			cardTitle={quantity ? 'Realtime broker(s) By Quantity' : 'Realtime broker(s) By amount'}
			headers={headers}
			buyers={buyers}
			sellers={sellers}
			analysisClick={analysisClick}
		/>
	);
};

export default connect(null, {
	viewRealTimBrokerWise
})(withRouter(RealTimeTopHoldingBrokerAnalysis));
