
export const stockHoldingTypes = {
	VIEW_STOCK_HOLDING: 'VIEW_STOCK_HOLDING',
	VIEW_STOCK_HOLDING_ERROR: 'VIEW_STOCK_HOLDING_ERROR',

	VIEW_TOP_VOLUME_STOCK_HOLDING: 'VIEW_TOP_VOLUME_STOCK_HOLDING',
	VIEW_TOP_VOLUME_STOCK_HOLDING_ERROR: 'VIEW_TOP_VOLUME_STOCK_HOLDING_ERROR',

	VIEW_TOP_SECTORWISE_STOCK_HOLDING: 'VIEW_TOP_SECTORWISE_STOCK_HOLDING',
	VIEW_TOP_SECTORWISE_STOCK_HOLDING_ERROR: 'VIEW_TOP_SECTORWISE_STOCK_HOLDING_ERROR',

	VIEW_TOP_SECTORNAME_STOCK_HOLDING: 'VIEW_TOP_SECTORNAME_STOCK_HOLDING',
	VIEW_TOP_SECTORNAME_STOCK_HOLDING_ERROR: 'VIEW_TOP_SECTORNAME_STOCK_HOLDING_ERROR',
		VIEW_STOCK_HOLDING_BY_BROKER_CODE: 'VIEW_STOCK_HOLDING_BY_BROKER_CODE'

};
