import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ListGroup, ListGroupItem } from 'reactstrap';

const PivotAnalysis = ({
  ypivotMovingAvgResponse,
  tpivotMovingAvgResponse,
}) => {
  return (
    <ListGroup flush className="pivot-analysis">
      {ypivotMovingAvgResponse ? (
        <>
          <ListGroupItem>Pivot Analysis</ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between bd-highlight">
            <span className="text-left">Support 3</span>
            <span className="text-left">{`T: Rs. ${tpivotMovingAvgResponse['support3']}`}</span>
            <span className="text-left">{`Y: Rs. ${ypivotMovingAvgResponse['support3']}`}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between bd-highlight">
            <span className="text-left">Support 2</span>
            <span className="text-left">{`T: Rs. ${tpivotMovingAvgResponse['support2']}`}</span>
            <span className="text-left">{`Y Rs. ${ypivotMovingAvgResponse['support2']}`}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between bd-highlight">
            <span className="text-left">Support 1</span>
            <span className="text-left">{`T Rs. ${tpivotMovingAvgResponse['support1']}`}</span>
            <span className="text-left">{`Y Rs. ${ypivotMovingAvgResponse['support1']}`}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between bd-highlight">
            <span className="text-left">Pivot</span>
            <span className="text-left">{`T Rs. ${tpivotMovingAvgResponse['pivot']}`}</span>
            <span className="text-left">{`Y Rs. ${ypivotMovingAvgResponse['pivot']}`}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between bd-highlight">
            <span className="text-left">Resistance 1</span>
            <span className="text-left">{`T Rs. ${tpivotMovingAvgResponse['resistance1']}`}</span>
            <span className="text-left">{`Y Rs. ${ypivotMovingAvgResponse['resistance1']}`}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between bd-highlight">
            <span className="text-left">Resistance 2</span>
            <span className="text-left">{`T Rs. ${tpivotMovingAvgResponse['resistance2']}`}</span>
            <span className="text-left">{`Y Rs. ${ypivotMovingAvgResponse['resistance2']}`}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between bd-highlight">
            <span className="text-left">Resistance 3</span>
            <span className="text-left">{`T Rs. ${tpivotMovingAvgResponse['resistance3']}`}</span>
            <span className="text-left">{`Y Rs. ${ypivotMovingAvgResponse['resistance3']}`}</span>
          </ListGroupItem>
          {/*<ListGroupItem>Moving Analysis</ListGroupItem>*/}
          {/*  <ListGroupItem className="d-flex justify-content-between bd-highlight">*/}
          {/*    <span className="text-left">MA5</span>*/}
          {/*    <span className="text-left">{`Rs. ${pivotMovingAvgResponse['movingAvg5']}`}</span>*/}
          {/*    <span className="text-left">{pivotMovingAvgResponse['signal5']}</span>*/}
          {/*  </ListGroupItem>*/}
          {/*  <ListGroupItem className="d-flex justify-content-between bd-highlight">*/}
          {/*    <span className="text-left">MA20</span>*/}
          {/*    <span className="text-left">{`Rs. ${pivotMovingAvgResponse['movingAvg20']}`}</span>*/}
          {/*    <span className="text-left">{pivotMovingAvgResponse['signal20']}</span>*/}
          {/*  </ListGroupItem>*/}
          {/*  <ListGroupItem className="d-flex justify-content-between bd-highlight">*/}
          {/*    <span className="text-left">MA180</span>*/}
          {/*    <span className="text-left">{`Rs. ${pivotMovingAvgResponse['movingAvg180']}`}</span>*/}
          {/*    <span className="text-left">{pivotMovingAvgResponse['signal180']}</span>*/}
          {/*  </ListGroupItem>*/}
        </>
      ) : (
        <div></div>
      )}
    </ListGroup>
  );
};
export default connect(null)(withRouter(PivotAnalysis));
