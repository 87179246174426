import axios from 'axios';

import { authenticationActionTypes } from './authentication.types';

export const URL = process.env.REACT_APP_ANALYZE_URL;

export const signout = (callback) => (dispatch) => {
  localStorage.clear();
	dispatch({
		type: authenticationActionTypes.AUTH_USER,
		payload: ''
	});

	if (callback) {
		callback();
	}
};



export const signin = ({ username, password }, callback) => async dispatch => {
    try {
      const response = await axios.post(`${URL}/auth`, {
        username,
        password,
      });
  
      console.log('sign in response',response.data);
  
      if (response.data.token && response.data.roles) {
        // remove previous user data from localstorage
        localStorage.removeItem('token');
        // add new user data to localstorage
        localStorage.setItem('role', response.data.roles[0].authority);
        localStorage.setItem('token', response.data.token);
      }
  
      dispatch({
        type: authenticationActionTypes.AUTH_USER,
        payload: response.data.token,
      });
  
      dispatch({
        type: authenticationActionTypes.SET_ROLE,
        payload: response.data.roles[0].authority,
      });
  
      callback();
    } catch (e) {
      console.log('exception',{ e });
      dispatch({
        type: authenticationActionTypes.AUTH_ERROR,
        payload: `* ${e.response.data.error}`,
      });
    }
  };

  export const setSubmitLoader = loader => {
    return {
      type: authenticationActionTypes.SET_SUBMIT_LOADER,
      payload: loader,
    };
  };
  export const setRole = role => {
    return {
      type: authenticationActionTypes.SET_ROLE,
      payload: role,
    };
  };
  export const setToken = token => {
    return {
      type: authenticationActionTypes.SET_TOKEN,
      payload: token,
    };
  };