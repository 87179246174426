export const historyActionTypes = {
  VIEW_HISTORY_BY_QUANTITY: 'VIEW_HISTORY_BY_QUANTITY',
  VIEW_HISTORY_BY_QUANTITY_ERROR: 'VIEW_HISTORY_BY_QUANTITY_ERROR',

  VIEW_HISTORY_BROKER_WISE_BY_QUANTITY: 'VIEW_HISTORY_BROKER_WISE_BY_QUANTITY',
  VIEW_HISTORY_BROKER_WISE_BY_QUANTITY_ERROR:
    'VIEW_HISTORY_BROKER_WISE_BY_QUANTITY_ERROR',

  VIEW_HISTORY_BROKER_WISE_BY_AMOUNT: 'VIEW_HISTORY_BROKER_WISE_BY_AMOUNT',
  VIEW_HISTORY_BROKER_WISE_BY_AMOUNT_ERROR:
    'VIEW_HISTORY_BROKER_WISE_BY_AMOUNT_ERROR',

  VIEW_N_DAY_AVERAGE_PRICE: 'VIEW_N_DAY_AVERAGE_PRICE',
  VIEW_N_DAY_AVERAGE_PRICE_ERROR: 'VIEW_N_DAY_AVERAGE_PRICE_ERROR',
};
