import React from 'react';

import { connect } from 'react-redux';
import { MyPortfolioAnalysis, SearchInput } from 'components';
import { withRouter } from 'react-router';
import { viewRealTimePortfolioAnalysis } from 'redux/Realtime/realtime.actions';
import { registration, unsubscribe } from 'hocs/websocket-listener';

class MyPortfolioAnalysisPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      realTimePortfolioAnalysisLoader: false,
      processed: 0,
      searchValue: '',
      portfolioAnalysis: [],
    };
    console.log(this.props);
  }
  componentDidMount() {
    // registration([
    // 	{
    // 		route: '/queue/mrmarketui.queue.floorsheet',
    // 		callback: this.refreshFloorsheetChanged
    // 	}
    // ]);
  }
  componentWillUnmount() {
    // unsubscribe([
    //   {
    //     route: '/queue/mrmarketui.queue.floorsheet',
    //     callback: this.refreshFloorsheetChanged,
    //   },
    // ]);
  }
  refreshFloorsheetChanged = message => {
    this.setState({ processed: parseInt(message.body) });
  };
  setLoaderState = (loaderName, loaderState) => {
    this.setState({ [loaderName]: loaderState });
  };
  addPortfolioAnalysis = added => {
    if (added) {
      this.setState({ portfolioAnalysis: this.props.portfolioAnalysis });
    }
  };
  searchList = e => {
    const { name, value } = e.target;
    if (value === '') {
      console.log(value);
      this.setState({ portfolioAnalysis: this.props.portfolioAnalysis });
    }
    this.setState({ [name]: value });
  };

  keyPressed = async e => {
    if (e.key === 'Enter') {
      this.searchClick();
    }
  };
  searchClick = async e => {
    this.setState({ realTimePortfolioAnalysisLoader: true });
    console.log(this.state.searchValue);
    const payload = this.props.portfolioAnalysis.filter(
      p => p.name.toLowerCase() === this.state.searchValue.trim().toLowerCase(),
    );
    console.log(payload);
    if (payload) {
      this.setState({ portfolioAnalysis: payload });
    }
    this.setState({ realTimePortfolioAnalysisLoader: false });
  };
  analysisClick = symbol => {
    console.log(symbol);
    this.props.history.push('analysis/' + symbol);
  };
  render() {
    return (
      <div key={'portfolio-analysis'}>
        <SearchInput
          key={'search'}
          name={'searchValue'}
          onChange={this.searchList}
          onKeyPress={this.keyPressed}
          value={this.state.searchValue}
        />
        <MyPortfolioAnalysis
          key={'pa'}
          loader={this.state.realTimePortfolioAnalysisLoader}
          setLoaderState={this.setLoaderState}
          addPortfolioAnalysis={this.addPortfolioAnalysis}
          token={this.props.token}
          portfolioAnalysis={this.state.portfolioAnalysis}
          processed={this.state.processed}
          analysisClick={this.analysisClick}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.authenticated,
    portfolioAnalysis: state.realtime.portfolioAnalysis,
  };
}
export default connect(mapStateToProps, {
  viewRealTimePortfolioAnalysis,
})(withRouter(MyPortfolioAnalysisPage));
