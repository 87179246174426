import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Col } from 'reactstrap';
import { DaywiseAnalysis } from 'components';

const ScriptAnalysis = ({
  pivotMovingAvgResponse,
  lastOneDays,
  lastTwoDays,
  lastThreeDays,
  lastSevenDays,
  lastFourteenDays,
  lastOneMonth,
  lastThreeMonth,
  lastSixMonth,
  lastOneYear,
  analysisClick,
  brokerClick,
}) => {
  if (pivotMovingAvgResponse) {
    delete pivotMovingAvgResponse.ltp;
    delete pivotMovingAvgResponse.symbol;
  }
  return (
    <>
      <Col lg={6} md={6} sm={12} xs={12}>
        <DaywiseAnalysis
          label={'Last One Days'}
          days={lastOneDays}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
        <DaywiseAnalysis
          label={'Last Two Days'}
          days={lastTwoDays}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <DaywiseAnalysis
          label={'Last Three Days'}
          days={lastThreeDays}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <DaywiseAnalysis
          label={'Last Seven Days'}
          days={lastSevenDays}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
        <DaywiseAnalysis
          label={'Last Fourteen Days'}
          days={lastFourteenDays}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <DaywiseAnalysis
          label={'Last One Month'}
          days={lastOneMonth}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
        <DaywiseAnalysis
          label={'Last Three Month'}
          days={lastThreeMonth}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <DaywiseAnalysis
          label={'Last Six Month'}
          days={lastSixMonth}
          analysisClick={analysisClick}
        />
        <DaywiseAnalysis
          label={'Last One Year'}
          days={lastOneYear}
          analysisClick={analysisClick}
          brokerClick={brokerClick}
        />
      </Col>
    </>
  );
};

export default connect(null)(withRouter(ScriptAnalysis));
