import React from 'react';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Loader } from 'components';
import DataTable from 'react-data-table-component';


const FilterComponent = ({ filterText, onFilter, onClear, classes }) => (


      <Input
        type="search"
        className="cr-search-form__input col-2"
        placeholder="Search..."
        onChange={onFilter}
        value={filterText}
        name="search"
      />
);
const TechnicalIndicatorHistory = ({
  loader,
  cardTitle,
  headers,
  technicalIndicators,
}) => {
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = technicalIndicators && technicalIndicators.filter(
    item =>
      item.date && item.date.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <Card className="mt-1">
      <CardHeader>{cardTitle}</CardHeader>
      <CardBody>
        {loader ? (
          <Loader />
        ) : (
          <DataTable
            columns={headers}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            fixedHeader
            fixedHeaderScrollHeight="600px"
            noHeader
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default connect(null)(withRouter(TechnicalIndicatorHistory));
