import axios from 'axios';
import { stockHoldingTypes } from './stockholding.types';
export const URL = process.env.REACT_APP_ANALYZE_URL;
export const viewStockHolding = ({ token, page = 0 }) => async (dispatch) => {
	try {
		let stockHoldingResponse;
		console.log('stockHoldingResponse');
		if (token) {
			stockHoldingResponse = await axios.get(`${URL}/realtime/analysis/topholding/${page}`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('stockHoldingResponse', { stockHoldingResponse });

		if (stockHoldingResponse.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_STOCK_HOLDING,
				payload: stockHoldingResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_STOCK_HOLDING_ERROR,
				payload: e.response.data.error
			});
		}
	}
};
export const viewStockHoldingByBrokerCode = ({ token,brokerCode, page = 0 }) => async (dispatch) => {
	try {
		let stockHoldingResponse;
		console.log('stockHoldingResponse');
		if (token) {
			stockHoldingResponse = await axios.get(`${URL}/realtime/analysis/topholding/bybroker/${brokerCode}`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('stockHoldingResponse', { stockHoldingResponse });

		if (stockHoldingResponse.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_STOCK_HOLDING_BY_BROKER_CODE,
				payload: stockHoldingResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_STOCK_HOLDING_ERROR,
				payload: e.response.data.error
			});
		}
	}
};

export const viewTopVolumeStockHolding = ({ token, page = 0, size = 10, top = 5 }) => async (dispatch) => {
	try {
		let stockHoldingResponse;
		if (token) {
			stockHoldingResponse = await axios.get(
				`${URL}/realtime/analysis/topholding/volumewise/${page}/${size}/${top}`,
				{
					headers: { Authorization: `Bearer ` + token }
				}
			);
		}

		console.log('viewTopVolumeStockHolding', { stockHoldingResponse });

		if (stockHoldingResponse.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_TOP_VOLUME_STOCK_HOLDING,
				payload: stockHoldingResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_TOP_VOLUME_STOCK_HOLDING_ERROR,
				payload: e.response.data.error
			});
		}
	}
};

export const viewTopSectorwiseStockHolding = ({ token, top = 1 }) => async (dispatch) => {
	try {
		console.log('sectowise');
		let stockHoldingResponse;
		if (token) {
			stockHoldingResponse = await axios.get(`${URL}/realtime/analysis/topholding/sectorwise/${top}`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('viewTopSectorwiseStockHolding', { stockHoldingResponse });

		if (stockHoldingResponse.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_TOP_SECTORWISE_STOCK_HOLDING,
				payload: stockHoldingResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_TOP_SECTORWISE_STOCK_HOLDING_ERROR,
				payload: e.response.data.error
			});
		}
	}
};


export const viewTopSectorNameStockHolding = ({ token, sectorName}) => async (dispatch) => {
	try {
		console.log('sectowise');
		let stockHoldingResponse;
		if (token) {
			stockHoldingResponse = await axios.get(`${URL}/realtime/analysis/topholdingbysectorwise/${sectorName}`, {
				headers: { Authorization: `Bearer ` + token }
			});
		}

		console.log('viewTopSectorwiseStockHolding', { stockHoldingResponse });

		if (stockHoldingResponse.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_TOP_SECTORNAME_STOCK_HOLDING,
				payload: stockHoldingResponse.data
			});
		}
	} catch (e) {
		console.log({ e });

		if (e.response && e.response.data) {
			dispatch({
				type: stockHoldingTypes.VIEW_TOP_SECTORNAME_STOCK_HOLDING_ERROR,
				payload: e.response.data.error
			});
		}
	}
};
