import axios from 'axios';
import {watchlistTypes} from './watchlist.types';
export const URL = process.env.REACT_APP_ANALYZE_URL;
export const viewWatchList =({token}) => async dispatch => {
    try {
        let droppricedifference;
        if (token) {
            droppricedifference = await axios.get(
            `${URL}/realtime/analysis/droppricedifference`,
            {
              headers: { Authorization: `Bearer ` + token },
            },
          );
        }
    
        console.log('droppricedifference', { droppricedifference });
    
        if (droppricedifference.data) {
          dispatch({
            type: watchlistTypes.VIEW_WATCHLIST,
            payload: droppricedifference.data,
          });
        }
      } catch (e) {
        console.log({ e });
    
        if (e.response && e.response.data) {
          dispatch({
            type: watchlistTypes.VIEW_WATCHLISTT,
            payload: e.response.data.error,
          });
        }
      }
};
