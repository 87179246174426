import {topbrokerholdingbydurationTypes} from './topbrokerholdingbyduration.types';

const initialState = {
    data: [],
    brokerData:[],
    sectorNameData:[],
    errorMessage: ''
};
export default function (state = initialState, action) {
    switch (action.type) {
        case topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_HOLDING_BY_DURATION:
            return {
                ...state,
                data: action.payload
            };
        case topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_SECTORNAME_HOLDING_BY_DURATION:
            return {
                ...state,
                sectorNameData: action.payload
            };
        case topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_HOLDING_BY_DURATION_ERROR:
        case topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_SECTORNAME_HOLDING_BY_DURATION_ERROR:
        case topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_CODE_HOLDING_BY_DURATION_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        case topbrokerholdingbydurationTypes.VIEW_TOP_BROKER_CODE_HOLDING_BY_DURATION:
            return {
                ...state,
                brokerData: action.payload
            };


        default:
            return state;
    }
}
