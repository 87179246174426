import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Row, Card, CardBody, CardHeader, Col} from 'reactstrap';
import {viewDurationBySectorName} from 'redux/TopBrokerHoldingByDuration/topbrokerholdingbyduration.actions';
import {viewTopSectorNameStockHolding} from 'redux/StockHolding/stockholding.actions';
import {DaywiseAnalysis, Loader, StockHoldingTable} from '../components';

class SectorNamePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            topBrokerHoldingLoader: false,
            processed: 0,
            stockHoldingCounter: 0,
        };
    }

    async componentDidMount() {
        await this.callApi();
    }

    callApi = async () => {
        this.setState({topBrokerHoldingLoader: true});
        await this.props.viewDurationBySectorName({
            token: this.props.token,
            sectorName: this.props.match.params.sectorName,
        });
        await this.props.viewTopSectorNameStockHolding({
            token: this.props.token,
            sectorName: this.props.match.params.sectorName,
        });
        this.setState({topBrokerHoldingLoader: false});
    };

    analysisClick = symbol => {
        console.log(symbol);
        this.props.history.push('../analysis/' + symbol);
    };
    brokerClick = brokerCode => {
        console.log(brokerCode);
        this.props.history.push('../broker/' + brokerCode);
    };

    render() {
        return (
            <>
                <Card className="mt-1">
                    <CardHeader>Top Broker Holding By Duration</CardHeader>
                    <CardBody>
                        {this.state.loader ? (
                            <Loader/>
                        ) : (
                            <Row className="m-2">
                                <Col sm={12} xs={12}>
                                    <StockHoldingTable
                                        holdings={this.props.sectorwises}
                                        tableTitle={'Sector'}
                                        headers={[
                                            'SN.',
                                            'Code',
                                            'Broker',
                                            'Symbol',
                                            'Sector',
                                            'Holding',
                                            'Holding (%)',
                                            'Buy',
                                            'Sell',

                                            'Ltp.'
                                        ]}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}
                                    />
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last One Days'}
                                        days={this.props.data['one']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}
                                        isLtp={true}
                                    />
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last Two Days'}
                                        days={this.props.data['two']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}
                                        isLtp={true}
                                    />
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last Three Days'}
                                        days={this.props.data['three']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}

                                        isLtp={true}/>
                                </Col>

                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last Seven Days'}
                                        days={this.props.data['seven']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}
                                        isLtp={true}
                                    />
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last Fourteen Days'}
                                        days={this.props.data['fourteen']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}

                                        isLtp={true}/>
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last One Month'}
                                        days={this.props.data['month']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}

                                        isLtp={true}/>
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last Three Month'}
                                        days={this.props.data['threemonth']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}

                                        isLtp={true}/>
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last Six Month'}
                                        days={this.props.data['sixmonth']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}

                                        isLtp={true}/>
                                </Col>
                                <Col sm={12} xs={12}>
                                    <DaywiseAnalysis
                                        showSymbol={true}
                                        label={'Last One Year'}
                                        days={this.props.data['oneyear']}
                                        analysisClick={this.analysisClick}
                                        brokerClick={this.brokerClick}
                                        isLtp={true}/>
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.authenticated,
        data: state.topbrokerholdingbyduration.sectorNameData,
        sectorwises: state.stockholding.sectorNames,
    };
}

export default connect(mapStateToProps, {
    viewDurationBySectorName,
    viewTopSectorNameStockHolding,
})(withRouter(SectorNamePage));
