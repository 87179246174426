import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col } from 'reactstrap';
import { NumberWidget } from '..';
import _ from 'lodash';
const PriceDifference = ({ dailyTrade, priceDifference }) => {
  return (
    <>
      <Col lg={3} md={6} sm={6} xs={12}>
        <NumberWidget
          title="Today"
          number={
            'Rs. ' +
            Number(
              dailyTrade.lastTradedPrice - dailyTrade.previousClose,
            ).toFixed(1)
          }
          color="secondary"
          progress={{
            value: Number(
              ((dailyTrade.lastTradedPrice - dailyTrade.previousClose) /
                dailyTrade.previousClose) *
                100,
            ).toFixed(2),
            label: 'Today',
          }}
        />
      </Col>
      <Col lg={3} md={6} sm={6} xs={12}>
        <NumberWidget
          title="Three Days"
          number={
            'Rs. ' + Number(priceDifference['threedays'].difference).toFixed(1)
          }
          color="secondary"
          progress={{
            value: Number(
              priceDifference['threedays'].ltpGainDropPercentage,
            ).toFixed(2),
            label: 'Today',
          }}
        />
      </Col>
      {_.isEmpty(priceDifference['week']) ? (
        <></>
      ) : (
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Week"
            number={
              'Rs. ' + Number(priceDifference['week'].difference).toFixed(1)
            }
            color="secondary"
            progress={{
              value: Number(
                priceDifference['week'].ltpGainDropPercentage,
              ).toFixed(2),
              label: 'Today',
            }}
          />
        </Col>
      )}
      {_.isEmpty(priceDifference['twoweek']) ? (
        <></>
      ) : (
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Two Week"
            number={
              'Rs. ' + Number(priceDifference['twoweek'].difference).toFixed()
            }
            color="secondary"
            progress={{
              value: Number(
                priceDifference['twoweek'].ltpGainDropPercentage,
              ).toFixed(2),
              label: 'Today',
            }}
          />
        </Col>
      )}
      {_.isEmpty(priceDifference['month']) ? (
        <></>
      ) : (
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Month"
            number={
              'Rs. ' + Number(priceDifference['month'].difference).toFixed(1)
            }
            color="secondary"
            progress={{
              value: Number(
                priceDifference['month'].ltpGainDropPercentage,
              ).toFixed(2),
              label: 'Today',
            }}
          />
        </Col>
      )}
      {_.isEmpty(priceDifference['threemonth']) ? (
        <></>
      ) : (
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Three Month"
            number={
              'Rs. ' +
              Number(priceDifference['threemonth'].difference).toFixed(1)
            }
            color="secondary"
            progress={{
              value: Number(
                priceDifference['threemonth'].ltpGainDropPercentage,
              ).toFixed(2),
              label: 'Today',
            }}
          />
        </Col>
      )}

      <Col lg={3} md={6} sm={6} xs={12}>
        <NumberWidget
          title="52WH"
          number={
            'Rs. ' +
            Number(
              dailyTrade.lastTradedPrice - dailyTrade.fiftyTwoWeekHigh,
            ).toFixed(1)
          }
          color="secondary"
          progress={{
            value: Number(
              ((dailyTrade.lastTradedPrice - dailyTrade.fiftyTwoWeekHigh) /
                dailyTrade.fiftyTwoWeekHigh) *
                100,
            ).toFixed(2),
            label: 'Today',
          }}
        />
      </Col>
      <Col lg={3} md={6} sm={6} xs={12}>
        <NumberWidget
          title="52WL"
          number={
            'Rs. ' +
            Number(
              dailyTrade.lastTradedPrice - dailyTrade.fiftyTwoWeekLow,
            ).toFixed(1)
          }
          color="secondary"
          progress={{
            value: Number(
              ((dailyTrade.lastTradedPrice - dailyTrade.fiftyTwoWeekLow) /
                dailyTrade.fiftyTwoWeekHigh) *
                100,
            ).toFixed(2),
            label: 'Today',
          }}
        />
      </Col>
    </>
  );
};

export default connect(null)(withRouter(PriceDifference));
